import React from "react";
import { SilverSunnStripeSubscription } from "@switcherstudio/switcher-api-client";
import styles from "../subscription/PlanGrids.module.scss";
import { useTranslation } from "react-i18next";
import { SubscriptionWithPrice } from "./";
import { calculateDiscountedAmount } from "helpers/stripe";
import { Widget } from "components/widgets/Widget";
import { Button } from "components/buttons/Button";

interface SubscriptionGridProps {
    subscriptions: SubscriptionWithPrice[] | undefined;
    displayCostFn: (
        amount?: number,
        showPeriodText?: boolean
    ) => string | undefined;
    openManagePlanModal: () => void;
}

export const NewSubscriptionGridV2: React.FC<SubscriptionGridProps> = ({
    subscriptions,
    displayCostFn,
    openManagePlanModal
}) => {
    const { t } = useTranslation();

    const displayDiscount = (sub: SilverSunnStripeSubscription) => {
        const amountOff = (sub.AmountOff || 0) / 100;

        if (sub.PercentOff !== null) {
            return ` (${t("misc:discount", { amount: sub.PercentOff + "%" })})`;
        } else if (amountOff > 0) {
            return ` (${t("misc:discount", { amount: "$" + amountOff })})`;
        }

        return "";
    };

    return (
        <>
            {subscriptions?.length === 0 ? (
                <Widget
                    title={t("subscription-page:plan-details").toUpperCase()}
                    details={[
                        {
                            title: t("subscription-page:no-active-plan")
                        }
                    ]}
                    button={
                        <Button type="primary" onClick={openManagePlanModal}>
                            {t("subscription-page:select-plan")}
                        </Button>
                    }
                    variant="billing"
                />
            ) : (
                <>
                    <div className={`${styles["plan-grid"]}`}>
                        {subscriptions?.map(({ subscription }, idx) => {
                            return (
                                <Widget
                                    key={idx}
                                    title={t(
                                        "subscription-page:plan-details"
                                    ).toUpperCase()}
                                    details={[
                                        {
                                            title: subscription.PlanName,
                                            children:
                                                displayCostFn(
                                                    calculateDiscountedAmount(
                                                        subscription?.Amount,
                                                        subscription
                                                    ),
                                                    true
                                                ) +
                                                displayDiscount(subscription)
                                        }
                                    ]}
                                    button={
                                        <button
                                            className="btn"
                                            onClick={openManagePlanModal}
                                        >
                                            {t("subscription-page:manage-plan")}
                                        </button>
                                    }
                                    variant="billing"
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};
