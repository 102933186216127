import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-navi";

import {
    useBeforeUnload as _useBeforeUnload,
    useBlocker
} from "react-router-dom";

const useReactRouterDom = import.meta.env.VITE_USE_REACT_ROUTER_DOM === "true";

export const useBeforeUnload = (
    shouldRun: boolean,
    handler: () => void,
    confirmOnExit: boolean = false
): void => {
    (useReactRouterDom ? useReactRouterBeforeUnload : useReactNaviBeforeUnload)(
        shouldRun,
        handler,
        confirmOnExit
    );
};

const useReactRouterBeforeUnload = (
    shouldRun: boolean,
    handler: () => void,
    confirmOnExit: boolean = false
): void => {
    const { t } = useTranslation();

    const handleBeforeUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (shouldRun) {
                if (confirmOnExit) {
                    event.preventDefault();
                    event.returnValue = "";
                } else {
                    handler && handler();
                }
            }
        },
        [shouldRun, handler, confirmOnExit]
    );

    _useBeforeUnload(handleBeforeUnload);

    const blocker = useBlocker(shouldRun && confirmOnExit);

    useEffect(() => {
        if (blocker.state === "blocked") {
            const message = t("message:confirm-before-unload");
            const response = window.confirm(message);
            if (response) {
                handler && handler();
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocker.state, handler, t]);
};

const useReactNaviBeforeUnload = (
    shouldRun: boolean,
    handler: () => void,
    confirmOnExit: boolean = false
): void => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleBeforeUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            if (shouldRun) {
                if (confirmOnExit) {
                    event.preventDefault();
                    event.returnValue = "";
                } else {
                    handler && handler();
                }
            }
        },
        [shouldRun, handler, confirmOnExit]
    );

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        const unblock =
            shouldRun && confirmOnExit
                ? history.block(t("message:confirm-before-unload"))
                : history.block(() => {
                      handleBeforeUnload;
                  });
        return () => {
            unblock();
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [shouldRun, handler, history, confirmOnExit, t, handleBeforeUnload]);
};
