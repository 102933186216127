import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-navi";
import styles from "./index.module.scss";
import { Toggle } from "components/inputs/toggle/Toggle";
import { useCallback, useEffect, useState } from "react";
import { EmbedPreview } from "components/embed-preview";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useCatalogData } from "hooks/useCatalogData";
import {
    FeaturedVideoType,
    SpecificVideo
} from "../CollectionPage/CollectionDefaultDisplayPage/SpecificVideo";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";

export const FeaturedTrailerPage: React.FC = () => {
    useCatalogAccessBanner();
    const { t } = useTranslation("featured-trailer-page");
    const {
        userInfo: { ProjectId }
    } = useSelector((state: RootState) => state.user);
    const [showTrailer, setShowTrailer] = useState<boolean>(false);
    const [selectedBroadcastId, setSelectedBroadcastId] = useState<string>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [embedPreviewKey, setEmbedPreviewKey] = useState(0);

    const { catalogData, embedCode } = useCatalogData({
        projectId: ProjectId,
        onSuccess: (data) => {
            setSelectedBroadcastId(data?.Details?.FeaturedBroadcastId);
            setShowTrailer(data?.Details?.EnableFeaturedBroadcast);
        },
        hideLoading: true
    });

    usePageHeader({
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("breadcrumbs:featured-trailer")
        ],
        title: t("featured-trailer-page:featured-trailer"),
        autoSave: isSaving,
        subTitle: (
            <Trans
                i18nKey={t("featured-trailer-page:featured-trailer-subtitle")}
                components={{
                    link1: (
                        <Link
                            href={"/catalog/featured-trailer"}
                            title="Featured Trailer"
                            className={styles["link"]}
                        />
                    )
                }}
            />
        )
    });

    const {
        dispatchApiRequest: addFeaturedVideo,
        loading: featuredVideoLoading
    } = useSwitcherClient((client) => client.catalog_PatchFeaturedVideo, {
        requestImmediately: false,
        hideLoading: true,
        onSuccess: () => {
            // Increment the embedPreviewKey to refresh the embed preview
            setEmbedPreviewKey((prev) => prev + 1);
        }
    });

    useEffect(() => {
        setIsSaving(featuredVideoLoading);
    }, [featuredVideoLoading]);

    const updateFeaturedVideo = useCallback(
        async (broadcastId: string, trailerEnabled: boolean) => {
            if (!catalogData?.Details?.Id) return;

            await addFeaturedVideo([
                catalogData?.Details?.Id,
                {
                    BroadcastId: !broadcastId ? null : broadcastId,
                    FeaturedBroadcastEnabled: trailerEnabled
                }
            ]);
        },
        [addFeaturedVideo, catalogData?.Details?.Id]
    );

    const handleToggle = (isEnabled: boolean) => {
        setShowTrailer(isEnabled);
        updateFeaturedVideo(selectedBroadcastId, isEnabled);
    };

    const onSelectedVideo = async (id) => {
        setSelectedBroadcastId(id);
        // Save to Catalog
        updateFeaturedVideo(id, showTrailer);
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-4">
                    <Toggle
                        on={showTrailer}
                        onToggle={handleToggle}
                        label={t(
                            "featured-trailer-page:featured-trailer-toggle"
                        )}
                        className={styles["toggle"]}
                        reverseLayout
                    />

                    <div className={styles["featured-trailer"]}>
                        <SpecificVideo
                            selectedBroadcastId={selectedBroadcastId}
                            onSelectBroadcastId={onSelectedVideo}
                            videoPlayerId={""}
                            featuredVideoType={FeaturedVideoType.Catalog}
                            disabled={!showTrailer}
                        />
                    </div>
                </div>
                <div className="col-xl-8">
                    <EmbedPreview
                        embedString={embedCode}
                        playerId={catalogData?.Details?.Id}
                        hasMaxHeight={true}
                        key={embedPreviewKey}
                    />
                </div>
            </div>
        </>
    );
};
