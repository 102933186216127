import styles from "./PageContentHeader.module.scss";
import { Badge } from "components/badges/Badge";
import Back from "assets/icons/chevron-left.svg?react";
import { Spinner } from "components/spinners/Spinner";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useIsMobile } from "hooks/useIsMobile";
import { Breadcrumbs } from "./Breadcrumbs";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useNavigate } from "hooks/useNavigate";
import { useCurrentRoute } from "hooks/useCurrentRoute";

const useNewBillingPage = import.meta.env.VITE_USE_NEW_BILLING_PAGE === "true";

interface PageContentHeaderProps {
    title: string;
    subTitle?: string | React.ReactNode;
    subTitleLink?: string;
    subTitleLinkText?: string;
    backNavigation?: string;
    backNavigationText?: string;
    showBadge?: boolean;
    badgeLabel?: string;
    badgeClass?: string;
    showUpgrade?: boolean;
    upgradeLabel?: string;
    customButtons?: React.ReactNode;
    inlineCustomButton?: React.ReactNode;
    shouldHeaderWrap?: boolean;
    autoSave?: boolean;
    autoSaveText?: string;
    showBreadcrumbs?: boolean;
    breadcrumbLabels?: string[];
}

export const PageContentHeader: React.FC<PageContentHeaderProps> = ({
    title,
    subTitle,
    subTitleLink,
    subTitleLinkText,
    backNavigation,
    backNavigationText,
    showBadge,
    badgeLabel,
    badgeClass,
    showUpgrade,
    upgradeLabel,
    customButtons,
    inlineCustomButton = false,
    shouldHeaderWrap = true,
    autoSave = false,
    autoSaveText,
    showBreadcrumbs,
    breadcrumbLabels
}) => {
    const { navigate } = useNavigate();
    const {
        url: { pathname }
    } = useCurrentRoute();

    const { t } = useTranslation("page-titles");
    const [autosaveAnimationTimer, setAutosaveAnimationTimer] =
        useState<boolean>(false);

    // trigger autosave animation
    useEffect(() => {
        if (autoSave) {
            setAutosaveAnimationTimer(true);
        }
    }, [autoSave]);

    const { isMobile } = useIsMobile();
    const hasCatalogClaim = useClaimCheck("catalog");

    // wait until api is not loading then display spinner for 1 second
    useEffect(() => {
        if (!autoSave && autoSave !== null) {
            setTimeout(() => setAutosaveAnimationTimer(false), 1000);
        }
    }, [autoSave]);

    return (
        <>
            <div className={styles["breadcrumb-container"]}>
                {backNavigation && (
                    <>
                        <button onClick={() => navigate(backNavigation)}>
                            <Back />
                            <small className="breadcrumb-text">
                                {backNavigationText ?? " "}
                            </small>
                        </button>
                    </>
                )}
                {showBreadcrumbs && (
                    <Breadcrumbs
                        pathname={pathname}
                        labels={breadcrumbLabels}
                    />
                )}
            </div>
            <div
                className={`${styles["header-container"]} ${
                    !shouldHeaderWrap ? styles["no-wrap"] : ""
                }`}
            >
                <div className={styles["left-container"]}>
                    <div className={styles["header-subheader-container"]}>
                        <h3 className={styles["header-title"]}>
                            <span>
                                {title ?? " "}
                                {(hasCatalogClaim || !isMobile) &&
                                    autosaveAnimationTimer && (
                                        <span className={styles["spinner"]}>
                                            {autoSaveText ?? t("saving")}
                                            <Spinner />
                                        </span>
                                    )}
                            </span>
                            {showBadge && (
                                <Badge
                                    className={badgeClass}
                                    tagText={badgeLabel}
                                />
                            )}
                            {inlineCustomButton && (
                                <div className={styles["header-inline-button"]}>
                                    {inlineCustomButton}
                                </div>
                            )}
                        </h3>
                        {subTitle && (
                            <small>
                                <div className={styles["subtitle"]}>
                                    {subTitle}
                                    {subTitleLinkText && subTitleLink && (
                                        <a
                                            href={subTitleLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {subTitleLinkText}
                                        </a>
                                    )}
                                </div>
                            </small>
                        )}
                    </div>
                </div>
                <div className={`${styles["right-container"]}`}>
                    {customButtons ??
                        (showUpgrade && (
                            <button
                                className="btn btn-primary"
                                onClick={() =>
                                    navigate(
                                        useNewBillingPage
                                            ? "/subscription#openModal"
                                            : "/subscription/subscribe"
                                    )
                                }
                            >
                                {upgradeLabel}
                            </button>
                        ))}
                    {!hasCatalogClaim && isMobile && autosaveAnimationTimer && (
                        <span className={styles["spinner"]}>
                            {t("saving")}
                            <Spinner />
                        </span>
                    )}
                </div>
            </div>
            <hr className={styles.break} />
        </>
    );
};
