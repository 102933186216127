import styles from "./index.module.scss";
import { ChevronIcon } from "components/icons/ChevronIcon";
import { PropsWithChildren } from "react";
import { useNavigate } from "hooks/useNavigate";

export interface NavDropdownItem {
    path: string;
    icon: React.ComponentType;
    action?: () => void;
    title: string;
}
interface NavDropdownProps extends PropsWithChildren {
    label: string;
    navItems: NavDropdownItem[];
    activeRoute: string;
    isOpen: boolean;
    toggleOpen: () => void;
    onToggle: () => void;
}

export const NavDropdown: React.FC<NavDropdownProps> = ({
    label,
    navItems,
    activeRoute,
    isOpen,
    children,
    toggleOpen,
    onToggle
}) => {
    const { navigate } = useNavigate();

    return (
        <div className={styles["sidebar-dropdown"]}>
            <div
                className={styles["sidebar-dropdown-title-container"]}
                onClick={toggleOpen}
            >
                <div className={styles["sidebar-dropdown-title"]}>{label}</div>
                <span>
                    <ChevronIcon direction={isOpen ? "down" : "up"} />
                </span>{" "}
            </div>
            <div
                className={`${styles["sidebar-dropdown-menu"]} ${
                    isOpen ? styles["open"] : ""
                }`}
            >
                {navItems.map((item, index) => {
                    const isActive = item.path === activeRoute;

                    return (
                        <div key={index}>
                            <a
                                href={item.path}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!!item.action) {
                                        item.action();
                                    } else {
                                        navigate(item.path);
                                    }
                                    onToggle();
                                }}
                                className={`
                                    ${isActive ? styles["active-route"] : ""}
                                `}
                            >
                                {item.icon && <item.icon />}
                                {item.title.toUpperCase()}
                            </a>
                        </div>
                    );
                })}
                {children}
            </div>
        </div>
    );
};
