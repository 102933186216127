import {
    CreateSubscriptionResponse,
    UpdateSubscriptionResponse
} from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { trackEvent, trackConversion } from "helpers/analyticsHelpers";
import { t } from "i18next";
import { useCallback, useMemo } from "react";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useBillingRedirect } from "./useBillingRedirect";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import mean from "lodash/mean";
import { AppDispatch } from "store/store";
import { loadStripe, PaymentIntent } from "@stripe/stripe-js";
import { parseBool } from "helpers/booleans";
import rollbar from "helpers/rollbar";

export interface useCheckoutRedirectOptions {
    onSuccess?: () => void;
    onFailure?: () => void;
}

export const useCheckoutRedirect = ({
    onSuccess,
    onFailure
}: useCheckoutRedirectOptions) => {
    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const { searchParams } = useMemo<URL>(
        () => new URL(window.location.href),
        []
    );

    const trackSubscription = useCallback(async (plan: string) => {
        const plans = await client.metrics_GetPlansLtv();
        let planInfo = plans.filter((p) => p.PlanId === plan)[0];

        if (!planInfo) {
            planInfo = {
                Ltv: Number(
                    mean(
                        plans.filter((p) => (p.Ltv || 0) > 0).map((p) => p.Ltv)
                    ).toFixed(2)
                ),
                PlanId: plan
            };
        }

        // legacy "Subscribed" event - deprecated
        trackEvent(
            "Subscribed",
            {
                currency: "USD",
                predicted_ltv: planInfo.Ltv,
                value: planInfo.Ltv,
                planId: planInfo.PlanId,
                category: "Billing",
                label: planInfo.PlanId
            },
            {
                integrations: {
                    Intercom: false,
                    HelpScout: false
                }
            }
        );

        trackConversion(
            true,
            "SilverSunnDashboard",
            await client.stripePrices_GetPrice(plan)
        );
    }, []);

    const makeSubscription = useCallback(
        async (retryStatus?: string) => {
            const planId = searchParams.get("planId");
            const isNewSubscription = parseBool(
                searchParams.get("isNewSubscription")
            );
            const currentSubscriptionId = searchParams.get(
                "currentSubscriptionId"
            );

            const resellerInventoryItemId = searchParams.get(
                "resellerInventoryItemId"
            );

            let subscriptionResponse:
                | CreateSubscriptionResponse
                | UpdateSubscriptionResponse = null;
            let orgId = userInfo.OrganizationId;
            let newSubId: string;

            if (!retryStatus) {
                const options = {
                    OrganizationId: orgId,
                    ResellerInventoryItemId: resellerInventoryItemId,
                    Plan: planId,
                    Quantity: 1
                };

                try {
                    if (isNewSubscription) {
                        // create new subscription - this is called if the user
                        // has NO subscription, which is possible if their previous
                        // subscription has been canceled and the cancellation date
                        // has passed
                        const res: CreateSubscriptionResponse =
                            await client.userSubscriptions_CreateSubscription(
                                userInfo.UserId,
                                {
                                    ...options,
                                    TrialDays: 0,
                                    Source: "SilverSunnDashboard"
                                }
                            );
                        subscriptionResponse = res;
                        newSubId = res.SubscriptionId;
                    } else {
                        // update user's current subscription
                        subscriptionResponse =
                            await client.userSubscriptions_UpdateSubscription(
                                userInfo.UserId,
                                currentSubscriptionId,
                                options
                            );
                    }

                    await trackSubscription(planId);
                } catch (e) {
                    if (e.status === 402) {
                        dispatch(
                            addNotification({
                                type: NotificationType.Danger,
                                message: t("subscription:update-error")
                            })
                        );
                    }
                }
            }
            return { newSubId, subscriptionResponse, orgId };
        },
        [searchParams, userInfo, trackSubscription, dispatch]
    );

    const onPayment = useCallback(
        async ({ payment_method }: PaymentIntent) => {
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_API_KEY
            );

            const isSubscription = parseBool(
                searchParams.get("isSubscription")
            );
            const isTrialing = parseBool(searchParams.get("isTrialing"));
            const currentSubscriptionId = searchParams.get(
                "currentSubscriptionId"
            );
            const invoiceId = searchParams.get("invoiceId");

            if (isSubscription) {
                const subscription = await makeSubscription();

                if (!subscription) {
                    throw new Error(t("errors:subscription-failure"));
                }

                // pull properties from the subscription object
                const { newSubId } = subscription;

                await client.userSubscriptions_ReloadSubscription(
                    userInfo.UserId,
                    currentSubscriptionId || newSubId
                );
            } else {
                const finalizedInvoice =
                    await client.stripeInvoices_FinalizeInvoice(
                        userInfo.UserId,
                        invoiceId
                    );

                if (!finalizedInvoice.paid) {
                    // If using a PaymentRequest, we must set handleActions to false, so that we can
                    // close the PaymentRequest overlay and allow the user to interact with potential
                    // modals for SCA/3DS, etc.
                    const paymentConfirmationResponse =
                        await stripe.confirmCardPayment(
                            finalizedInvoice.payment_intent_secret
                        );

                    if (paymentConfirmationResponse.error) {
                        throw paymentConfirmationResponse.error;
                    }

                    if (
                        paymentConfirmationResponse?.paymentIntent?.status ===
                        "requires_action"
                    ) {
                        const reconfirmationResponse =
                            await stripe.confirmCardPayment(
                                paymentConfirmationResponse.paymentIntent
                                    .client_secret
                            );

                        if (reconfirmationResponse?.error) {
                            throw reconfirmationResponse.error;
                        }
                    }
                }

                await client.purchaseEntitlements_PostUserPurchaseEntitlement(
                    userInfo.UserId,
                    finalizedInvoice.id
                );

                if (isTrialing) {
                    await client.stripe_DeleteSubscription(
                        currentSubscriptionId,
                        false
                    );
                }
            }

            // Sets the selected payment method as primary after payment
            await client.userPaymentMethods_SetPrimaryPaymentMethod(
                userInfo.UserId,
                typeof payment_method === "string"
                    ? payment_method
                    : payment_method.id
            );
        },
        [makeSubscription, searchParams, userInfo.UserId]
    );

    useBillingRedirect({
        onPaymentIntent: async (paymentIntent) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    await onPayment(paymentIntent);
                    onSuccess?.();
                    break;
                case "requires_payment_method":
                    onFailure?.();
                    break;
                case "processing":
                    rollbar.info(
                        `Payment Intent is processing: ${paymentIntent.id}`
                    );
                    break;
                default:
                    // Handle any unexpected status
                    rollbar.warning(
                        `Unexpected payment status: ${paymentIntent.status}`
                    );
                    break;
            }
        }
    });
};
