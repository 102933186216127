import { useState, useCallback, useEffect } from "react";
import { CouponResponse } from "@switcherstudio/switcher-api-client";
import rollbar from "helpers/rollbar";
import { RootState } from "store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { AppDispatch } from "store/store";
import { useSwitcherClient } from "./useSwitcherClient";
import { exists } from "helpers/booleans";

export interface UseGetLastClaimedCouponArgs {
    userId?: string;
    couponCode?: string;
    lazyLoad?: boolean;
    onChange?: (claimedCoupon: CouponResponse) => void;
    onClaimSuccess?: (coupon: CouponResponse) => void;
}

/**
 * This hook allows access to the most recently claimed coupon on a user's account and provides functions to add or remove a coupon
 * @param {string} userId the userId of the user to check
 * @param {string} couponCode if a coupon code is present, it will override the claimed coupon and instead claim and return the CouponResponse for the coupon code
 */
export function useGetLastClaimedCoupon({
    userId,
    couponCode,
    lazyLoad,
    onChange,
    onClaimSuccess
}: UseGetLastClaimedCouponArgs = {}): {
    claimedCoupon: CouponResponse;
    addClaimedCoupon: (coupon: string) => Promise<void>;
    removeClaimedCoupon: () => Promise<void>;
    getClaimedCoupon: () => Promise<CouponResponse>;
    loading: boolean;
    error: boolean;
} {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { userInfo } = useSelector((state: RootState) => state.user);

    const [claimedCoupon, setCoupon] = useState<CouponResponse>();
    const { dispatchApiRequest: getCoupons } = useSwitcherClient(
        (client) => client.coupons_GetClaimedCouponCodesByUser,
        { hideLoading: true }
    );

    const { dispatchApiRequest: claimCouponCode } = useSwitcherClient(
        (client) => client.coupons_ClaimCouponCode,
        {
            requestImmediately: false,
            hideLoading: true,
            onSuccess: onClaimSuccess
        }
    );

    const { dispatchApiRequest: removeCouponCode } = useSwitcherClient(
        (client) => client.coupons_ReleaseCouponCode,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    useEffect(() => {
        onChange && onChange(claimedCoupon);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimedCoupon]);

    /**
     * Gets the most recently applied Coupon response from an array of Coupon Responses
     * @param {Array<CouponResponse>} coupons the array of Coupon Responses to be queried
     * @returns {CouponResponse} the most recently applied Coupon Response, returns null if no coupon is present
     */
    const getMostRecentCoupon = useCallback(
        (coupons: CouponResponse[]): CouponResponse => {
            if (coupons.length > 0) {
                return coupons.sort((a: CouponResponse, b: CouponResponse) =>
                    Date.parse(a.ResellerInventoryItem.UpdatedAt) >
                    Date.parse(b.ResellerInventoryItem.UpdatedAt)
                        ? -1
                        : 1
                )[0];
            } else {
                return null;
            }
        },
        []
    );

    /**
     * Claims the coupon in the database to the user and updates claimedCoupon
     * @param {string} coupon the coupon code for the coupon that is to be claimed
     * @returns {void} does not return a value directly, but updates claimedCoupon
     */
    const addClaimedCoupon = useCallback(
        async (coupon: string) => {
            try {
                const coupons = await getCoupons([userId ?? userInfo?.UserId]);
                // only claim the coupon if it isn't already claimed
                if (
                    !coupons?.find(
                        (c) =>
                            c?.ResellerInventoryItem?.CouponCode?.trim() ===
                            coupon
                    )
                ) {
                    setLoading(true);
                    setError(false);
                    await claimCouponCode([userId ?? userInfo?.UserId, coupon]);
                }
                setCoupon(
                    getMostRecentCoupon(
                        await getCoupons([userId ?? userInfo?.UserId])
                    )
                );
            } catch (e) {
                setCoupon(null);
                setError(true);
                rollbar.error("Error adding claimed coupons", e);
            } finally {
                setLoading(false);
            }
        },
        [
            getCoupons,
            userId,
            userInfo?.UserId,
            claimCouponCode,
            getMostRecentCoupon
        ]
    );

    const getClaimedCoupon = useCallback(async () => {
        const claimedCoupon = getMostRecentCoupon(
            await getCoupons([userId ?? userInfo?.UserId])
        );
        setCoupon(claimedCoupon);
        return claimedCoupon;
    }, [getMostRecentCoupon, getCoupons, userId, userInfo?.UserId]);

    // Handles initial setup.  If predefined coupon present, claim it
    useEffect(() => {
        if (!claimedCoupon && !lazyLoad) {
            if (exists(couponCode)) {
                addClaimedCoupon(couponCode);
            } else {
                getClaimedCoupon();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimedCoupon, couponCode, lazyLoad]);

    /**
     * Un-claims coupon and returns it to an "Available" status in the database, as well as set claimedCoupon to undefined
     * @returns {void} does not return a value directly, but updates claimedCoupon
     */
    const removeClaimedCoupon = useCallback(async () => {
        try {
            setLoading(true);
            await removeCouponCode([
                userId ?? userInfo?.UserId,
                claimedCoupon.ResellerInventoryItem.Id
            ]);

            if (
                !claimedCoupon.ResellerInventoryItem?.ResellerInventory
                    ?.IsSilent
            ) {
                dispatch(
                    addNotification({
                        type: NotificationType.Info,
                        message: "coupon-form:removed-coupon",
                        messageOptions: {
                            couponInput:
                                claimedCoupon.ResellerInventoryItem.CouponCode
                        }
                    })
                );
            }

            getClaimedCoupon();
        } catch (e) {
            rollbar.error("Error removing claimed coupon", e);
        } finally {
            setLoading(false);
        }
        setCoupon(null);
    }, [
        removeCouponCode,
        userId,
        userInfo?.UserId,
        claimedCoupon,
        getClaimedCoupon,
        dispatch
    ]);

    return {
        claimedCoupon,
        addClaimedCoupon,
        removeClaimedCoupon,
        getClaimedCoupon,
        loading,
        error
    };
}
