import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { useCallback } from "react";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { Button } from "components/buttons/Button";
import { useCatalogAccessBanner } from "./hooks/useCatalogAccessBanner";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { RootState } from "store/reducers";
import { CreateOrUpdateSwitcherPlayerModal } from "components/modal/CreateOrUpdateSwitcherPlayerModal";
import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable
} from "react-beautiful-dnd";
import { PlayerDetails } from "components/entity-details/PlayerDetails";
import { useNavigate } from "hooks/useNavigate";
import {
    DeviceType,
    VideoPlayer,
    VideoPlayersResponse
} from "@switcherstudio/switcher-api-client";
import { DeleteModal } from "components/modal/DeleteModal";
import rollbar from "helpers/rollbar";
import { useSettableMemo } from "hooks/useSettableMemo";
import { Spinner } from "components/spinners/Spinner";
import { usePageHeader } from "hooks/usePageHeader";
import { openExternalUrlInNewTab } from "helpers/navigation";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsTablet } from "hooks/useIsTablet";
import { CatalogLinkList } from "./CatalogLinkList";
import { Tabs } from "components/tabs/Tabs";
import { CatalogTabs } from "store/view/types";
import { Link } from "components/buttons/Link";
import { useCatalogData } from "hooks/useCatalogData";
import { CopyEmbedCode } from "views/page-content/CatalogPage/CopyEmbedCode/CopyEmbedCode";
import { ShareLink } from "./ShareLink/ShareLink";

export const CatalogPage: React.FC = () => {
    useCatalogAccessBanner();
    const { userInfo } = useSelector((state: RootState) => state.user);
    const { t } = useTranslation("catalog-page");
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    useRedirectIfDisallowed(() => !videoPlayerDisabled, "/switcher-players");
    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const [deletePlayerModalOpen, setDeletePlayerModalOpen] =
        useState<boolean>(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState<string>();
    const dispatch = useDispatch<AppDispatch>();
    const hasVideoPlayerAccess = useClaimCheck("videoplayer");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");
    const { isMobile } = useIsMobile();
    const { isTablet } = useIsTablet();

    const [activeTab, setActiveTab] = useState<CatalogTabs>(
        CatalogTabs.Collections
    );

    const { catalogData, shareLink, catalogUpdateCustomSlug } = useCatalogData({
        projectId: userInfo?.ProjectId
    });

    const { data: videoLibraryUsage } = useSwitcherClient(
        (client) => client.cloudRecordings_GetUsage,
        {
            requestImmediately: true
        }
    );

    const { navigate } = useNavigate();

    const { dispatchApiRequest: deletePlayer } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_DeleteVideoPlayer
    );

    const { data: response, dispatchApiRequest: refetchPlayers } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_GetVideoPlayers,
            {
                requestImmediately: true,
                hideLoading: true,
                args: [userInfo?.ProjectId, true, true]
            }
        );

    const [videoPlayersResponse, setVideoPlayerResponse] = useSettableMemo(
        () => response,
        [response]
    );

    const hasCollections = useMemo(
        () =>
            !videoPlayersResponse?.every(
                (response) => response.VideoPlayer.IsLibraryPlayer
            ),
        [videoPlayersResponse]
    );

    const { dispatchApiRequest: updatePlayer, loading: loadingUpdate } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_PutVideoPlayer,
            {
                requestImmediately: false,
                hideLoading: true,
                onError: () => refetchPlayers()
            }
        );

    const { dispatchApiRequest: sortVideoPlayer, loading: loadingSort } =
        useSwitcherClient((client) => client.videoPlayers_SortVideoPlayer, {
            requestImmediately: false,
            hideLoading: true,
            onSuccess: (response) => {
                setVideoPlayerResponse(response);
            },
            onError: (e) => {
                rollbar.error("Error reordering collection", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("messages:failed-to-reorder-collections")
                    })
                );
                refetchPlayers();
            }
        });

    usePageHeader({
        autoSave: loadingUpdate || loadingSort,
        showBreadcrumbs: false
    });

    // clear switcher player app from embed.js
    useEffect(() => {
        return () => {
            window.switcherPlayerApp = undefined;
        };
    }, []);

    const handleDeleteConfirm = useCallback(() => {
        deletePlayer([selectedPlayerId, userInfo?.ProjectId])
            .then(() => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("collections:messages:delete-success")
                    })
                );
                refetchPlayers();
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("collections:errors:delete-error")
                    })
                );
            })
            .finally(() => setDeletePlayerModalOpen(false));
    }, [
        deletePlayer,
        selectedPlayerId,
        userInfo?.ProjectId,
        dispatch,
        t,
        refetchPlayers
    ]);

    const handleDragEnd = useCallback(
        async (result: DropResult) => {
            if (!result?.destination) return;
            if (result.reason === "DROP") {
                const collections = [...videoPlayersResponse];

                const targetPlayerIndex = collections.findIndex(
                    (p) => p.VideoPlayer.Id === result?.draggableId
                );

                if (targetPlayerIndex === result?.destination.index) return;

                const targetPlayer = collections[targetPlayerIndex];

                collections.splice(result.source.index, 1);
                collections.splice(result.destination.index, 0, targetPlayer);

                setVideoPlayerResponse(collections);

                const destinationPreviousRank =
                    collections[result.destination.index - 1]?.VideoPlayer
                        .OrdinalRank.Rank;
                const destinationNextRank =
                    collections[result.destination.index + 1]?.VideoPlayer
                        .OrdinalRank?.Rank;

                await sortVideoPlayer([
                    {
                        VideoPlayer: targetPlayer.VideoPlayer,
                        PreviousOrdinal: destinationPreviousRank,
                        NextOrdinal: destinationNextRank
                    },
                    targetPlayer.VideoPlayer.Id
                ]);
            }
        },
        [setVideoPlayerResponse, sortVideoPlayer, videoPlayersResponse]
    );

    const onPrivacyToggle = useCallback(
        async (player: VideoPlayer, value: boolean) => {
            await updatePlayer([
                player.Id,
                {
                    VideoPlayer: {
                        ...player,
                        ShowInCatalog: value
                    }
                },
                userInfo?.ProjectId
            ]);

            await refetchPlayers();
        },
        [refetchPlayers, updatePlayer, userInfo?.ProjectId]
    );

    const handlePreviewClick = useCallback(
        (type: "desktop" | "tablet" | "mobile") => {
            switch (type) {
                case "desktop":
                    openExternalUrlInNewTab(`${shareLink}`);
                    break;
                case "tablet":
                    if (isTablet) {
                        openExternalUrlInNewTab(`${shareLink}`);
                    } else {
                        openExternalUrlInNewTab(
                            `device-preview?device=${DeviceType.IPad}&catalogId=${catalogData?.Details?.Id}`
                        );
                    }
                    break;
                case "mobile":
                    if (isMobile) {
                        openExternalUrlInNewTab(`${shareLink}`);
                    } else {
                        openExternalUrlInNewTab(
                            `device-preview?device=${DeviceType.IPhone}&catalogId=${catalogData?.Details?.Id}`
                        );
                    }
                    break;
                default:
                    openExternalUrlInNewTab(`${shareLink}`);
            }
        },
        [shareLink, isTablet, isMobile, catalogData?.Details?.Id]
    );

    const createCollectionButton = useMemo(() => {
        return (
            hasVideoPlayerAccess &&
            !videoPlayerDisabled && (
                <>
                    <div
                        className={`${styles["collection-button"]} ${styles["header-button"]}`}
                    >
                        <Button
                            type="primary"
                            onClick={() => setCreateModalOpen(true)}
                        >
                            {hasCollections
                                ? t("collections-page:create-collection")
                                : t("collections-page:create-first-collection")}
                        </Button>
                    </div>
                    <CreateOrUpdateSwitcherPlayerModal
                        isOpen={createModalOpen}
                        setIsOpen={setCreateModalOpen}
                        type="create"
                        onSubmit={(vpr: VideoPlayersResponse) => {
                            navigate(
                                `/catalog/collections/${vpr?.VideoPlayers?.[0]?.Id}`
                            );
                        }}
                    />
                </>
            )
        );
    }, [
        createModalOpen,
        hasCollections,
        hasVideoPlayerAccess,
        navigate,
        t,
        videoPlayerDisabled
    ]);

    const collections = useMemo(() => {
        const hasVideos = videoLibraryUsage?.TotalRecordings > 0;

        return (
            <>
                {createCollectionButton}
                {!hasVideos && (
                    <p>
                        {t("catalog-page:no-collections")}{" "}
                        <Link href="/video-library">
                            {t("catalog-page:upload-prompt")}
                        </Link>
                    </p>
                )}
                <div
                    className={`${styles["collections-container"]} ${
                        videoPlayerDisabled && styles["collections-disabled"]
                    }`}
                >
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="collections">
                            {(droppableProvided, snapshot) => (
                                <div
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}
                                >
                                    {videoPlayersResponse
                                        ?.filter(
                                            (resp) =>
                                                hasVideos ||
                                                !resp.VideoPlayer
                                                    .IsLibraryPlayer
                                        )
                                        .map((vpr, index) => (
                                            <Draggable
                                                draggableId={
                                                    vpr?.VideoPlayer?.Id ??
                                                    (index + 1).toString()
                                                }
                                                key={
                                                    vpr?.VideoPlayer?.Id ??
                                                    (index + 1).toString()
                                                }
                                                index={index}
                                            >
                                                {(draggableProvided) => (
                                                    <div
                                                        className={`${styles["collection-item"]}`}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        ref={
                                                            draggableProvided.innerRef
                                                        }
                                                    >
                                                        <PlayerDetails
                                                            item={vpr}
                                                            key={
                                                                vpr.VideoPlayer
                                                                    .Id
                                                            }
                                                            showEdit={
                                                                !vpr.VideoPlayer
                                                                    .IsLibraryPlayer
                                                            }
                                                            showDelete={
                                                                !vpr.VideoPlayer
                                                                    .IsLibraryPlayer
                                                            }
                                                            showShare={true}
                                                            showPrivacyToggle={
                                                                true
                                                            }
                                                            isDraggable={true}
                                                            onPrivacyToggle={
                                                                onPrivacyToggle
                                                            }
                                                            handleDelete={(
                                                                videoPlayerId
                                                            ) => {
                                                                setSelectedPlayerId(
                                                                    videoPlayerId
                                                                );
                                                                setDeletePlayerModalOpen(
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    <div
                                        className={
                                            snapshot.isDraggingOver
                                                ? styles["placeholder-div"]
                                                : ""
                                        }
                                    >
                                        {droppableProvided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <DeleteModal
                    isOpen={deletePlayerModalOpen}
                    setIsOpen={setDeletePlayerModalOpen}
                    onSubmit={handleDeleteConfirm}
                    titleText={t(
                        "collections:messages:delete-collection-title"
                    )}
                    subMsg={
                        videoPlayersResponse?.find(
                            (vpr) =>
                                vpr.VideoPlayer.Id === selectedPlayerId &&
                                // if the video player OR any of the broadcasts in the player have entitlements
                                (vpr.VideoPlayerEntitlements
                                    ?.ProductEntitlements?.length > 0 ||
                                    vpr.BroadcastEntitlements
                                        ?.ProductEntitlements.length > 0)
                        )
                            ? t("collections:messages:delete-collection-gated")
                            : t("collections:messages:delete-collection")
                    }
                />
            </>
        );
    }, [
        createCollectionButton,
        deletePlayerModalOpen,
        handleDeleteConfirm,
        handleDragEnd,
        onPrivacyToggle,
        selectedPlayerId,
        t,
        videoLibraryUsage,
        videoPlayerDisabled,
        videoPlayersResponse
    ]);

    const handleUpdateCustomSlug = useCallback(
        (customSlug: string) => {
            catalogUpdateCustomSlug([catalogData?.Details?.Id, customSlug]);
        },
        [catalogData?.Details?.Id, catalogUpdateCustomSlug]
    );

    const share = useMemo(() => {
        return (
            <>
                <CopyEmbedCode
                    entityId={catalogData?.Details?.Id}
                    variant="catalog"
                />
                <ShareLink
                    currentSlug={catalogData?.Details?.CustomSlug}
                    shareLink={shareLink}
                    variant="catalog"
                    updateCustomSlug={handleUpdateCustomSlug}
                />
            </>
        );
    }, [
        catalogData?.Details?.Id,
        shareLink,
        handleUpdateCustomSlug,
        catalogData?.Details?.CustomSlug
    ]);

    const settings = useMemo(() => {
        return (
            <div className={styles["link-list"]}>
                <CatalogLinkList
                    hasVideoPlayerAccess={hasVideoPlayerAccess}
                    hasGatedContentAccess={hasGatedContentAccess}
                    t={t}
                    handlePreview={handlePreviewClick}
                />
            </div>
        );
    }, [handlePreviewClick, hasGatedContentAccess, hasVideoPlayerAccess, t]);

    return (
        <>
            {!videoPlayersResponse ? (
                <div className={styles["loading"]}>
                    <Spinner size={128} />
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-8">
                        <div className={styles["tab-view"]}>
                            {isMobile && (
                                <Tabs<CatalogTabs>
                                    tabs={[
                                        {
                                            id: CatalogTabs.Collections,
                                            label: t(
                                                "catalog-page:Collections"
                                            ),
                                            component: collections
                                        },
                                        {
                                            id: CatalogTabs.Settings,
                                            label: t("catalog-page:settings"),
                                            component: settings
                                        },
                                        {
                                            id: CatalogTabs.Share,
                                            label: t("catalog-page:share"),
                                            component: share
                                        }
                                    ]}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                            )}
                        </div>
                        {!isMobile && collections}
                    </div>
                    <div className="col-md-4">
                        {!isMobile && settings}

                        {!isMobile && share}
                    </div>
                </div>
            )}
        </>
    );
};
