import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { facebook as facebookClient } from "api/facebook/facebook-client";
import styles from "./Facebook.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { PillSelector } from "components/pill-selector/PillSelector";
import {
    resetFacebookForm,
    updateFacebookForm,
    updateGeneralForm,
    updateSelectedStream,
    updateQuality
} from "store/platforms/slice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "hooks/useNavigate";
import { Toggle } from "components/inputs/toggle/Toggle";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import {
    FacebookCrosspostResponse,
    FacebookProfile
} from "store/platforms/types";
import { SearchableSelect } from "components/searchable-select/SearchableSelect";
import { SpeedTest } from "components/speed-test/SpeedTest";
import { ChannelSetting } from "../platform-helper";
import {
    MultiSelectWrapper,
    OnSearchReturnType
} from "components/multi-select/MultiSelectWrapper";
import { facebook as fb } from "api/facebook/facebook-client";
import { ScheduledOptions } from "../forms/types";
import rollbar from "helpers/rollbar";
import { useClaimCheck } from "hooks/useClaimCheck";

interface FacebookFormProps {
    platform?: StreamingProvider;
    multiForm?: boolean;
    destinationType?: string;
    destinationId?: number | string;
    showSpeedtest?: boolean;
    destination?: FacebookProfile;
}

export const FacebookForm: React.FC<FacebookFormProps> = ({
    multiForm,
    showSpeedtest = true,
    destination
}: FacebookFormProps) => {
    const { t } = useTranslation("platforms");
    const isMounted = useIsMountedRef();
    const dispatch = useDispatch<AppDispatch>();
    const { navigate } = useNavigate();

    const [isLoaded, setIsLoaded] = useState(false);
    const [crosspostingOptions, setCrosspostingOptions] = useState(
        [] as Array<any>
    );
    const [enableContentTags, setEnableContentTags] = useState(false);
    const { platforms, user } = useSelector((state: RootState) => state);
    const { facebook, general } = platforms;
    const { facebookForms, privacyOptions } = facebook;
    const selectedDestination = destination || facebook.selectedDestination;
    const facebookForm = facebookForms[selectedDestination.id];
    const {
        selectedStreams: { facebook: selectedStream },
        formState
    } = general;
    const [currentStream, setCurrentStream] = useState(null);
    const hasFBBrandedContent = useClaimCheck("fbbrandedcontent");
    const brandedContentAllowed = useMemo(
        () => hasFBBrandedContent && selectedDestination.edge === "accounts",
        [hasFBBrandedContent, selectedDestination.edge]
    );
    const privacyPills = privacyOptions.map((p) => p.name);

    useEffect(() => {
        async function init() {
            if (selectedDestination.id === "-1")
                navigate(!multiForm ? "/platforms/facebook" : ""); // not sure where to point this in multiForm mode...

            dispatch(resetFacebookForm(selectedDestination.id));
            if (selectedDestination.isTestStream)
                dispatch(
                    updateFacebookForm({
                        [selectedDestination.id]: { privacy: privacyOptions[2] }
                    })
                );

            dispatch(updateSelectedStream({ id: "facebook", stream: null }));

            setIsLoaded(true);
        }

        if (isMounted.current && !isLoaded) init();
    }, [
        isMounted,
        dispatch,
        isLoaded,
        selectedDestination.id,
        multiForm,
        navigate,
        privacyOptions,
        selectedDestination.isTestStream,
        selectedDestination.edge
    ]);

    useEffect(() => {
        if (
            formState === ScheduledOptions.Existing &&
            JSON.stringify(currentStream) !== JSON.stringify(selectedStream)
        ) {
            setCurrentStream(selectedStream);

            dispatch(
                updateGeneralForm({
                    title: selectedStream?.title,
                    description: selectedStream?.description,
                    scheduledStartTime: new Date(
                        selectedStream?.broadcast_start_time
                    )
                })
            );
        }
    }, [dispatch, selectedStream, currentStream, formState]);

    useEffect(() => {
        const populateCrosspostingOptions = async () => {
            if (
                selectedDestination.edge === "accounts" &&
                facebookForm?.enableCrossposting
            ) {
                try {
                    const results: FacebookCrosspostResponse =
                        await facebookClient.crossPostOptions.get(
                            selectedDestination.id
                        );
                    setCrosspostingOptions(results.data);
                } catch (e) {
                    dispatch(
                        updateFacebookForm({
                            [selectedDestination.id]: {
                                enableCrossposting: false
                            }
                        })
                    );

                    const isPermissionError = String(e).includes("(#200)");

                    rollbar.error(
                        "Error getting and setting crossposting options",
                        e,
                        { platformId: "facebook" }
                    );

                    if (isPermissionError) {
                        dispatch(
                            addNotification({
                                type: NotificationType.Danger,
                                message: "errors:crosspost-permissions-error"
                            })
                        );
                    }
                }
            }
        };
        populateCrosspostingOptions();
    }, [
        dispatch,
        selectedDestination.edge,
        selectedDestination.id,
        facebookForm
    ]);

    function onPrivacyChange(privacy) {
        const selectedPrivacy = privacyOptions.filter(
            (q) => q.name === privacy
        )[0];
        dispatch(
            updateFacebookForm({
                [selectedDestination.id]: { privacy: selectedPrivacy }
            })
        );
    }

    function onToggle(key: string) {
        dispatch(
            updateFacebookForm({
                [selectedDestination.id]: { [key]: !facebookForm[key] }
            })
        );
    }

    const onSpeedTestComplete = useCallback(
        (channel: ChannelSetting) => {
            dispatch(
                updateQuality({
                    name: `${channel["video-frame-height"]}p`,
                    setting: channel
                })
            );
        },
        [dispatch]
    );

    const onSelect = useCallback(
        (key, options) => {
            dispatch(
                updateFacebookForm({
                    [selectedDestination.id]: { [key]: options }
                })
            );
        },
        [dispatch, selectedDestination.id]
    );

    const onSearch = async (filter: string): Promise<OnSearchReturnType[]> => {
        const result = await fb.contentTags.get(filter);
        const opts = result?.data
            ?.map((r) => ({ value: r.id, label: r.name, ...r }))
            .filter(
                (r) => !facebookForm?.selectedTags.some((t) => t.id === r.id)
            )
            .map((r) => ({
                disabled: facebookForm.selectedTags.length >= 10,
                ...r
            }));

        return opts;
    };

    const onBrandedSelect = useCallback(
        (option) => {
            dispatch(
                updateFacebookForm({
                    [selectedDestination.id]: {
                        brandedContentSelection: option.id
                    }
                })
            );
        },
        [dispatch, selectedDestination.id]
    );

    if (!facebookForm) {
        return <></>;
    }

    return (
        <>
            {!multiForm && selectedStream === null && showSpeedtest && (
                <>
                    <div className="row mt-2 mb-2">
                        <SpeedTest
                            onComplete={onSpeedTestComplete}
                            hasFullHD={
                                user?.userInfo?.FeatureClaims?.indexOf(
                                    "1080p"
                                ) !== -1
                            }
                        />
                    </div>
                </>
            )}
            {!selectedDestination.isTestStream &&
                selectedDestination.edge === "me" &&
                selectedStream === null && (
                    <div className="form-group">
                        <label>{t("share-with")}</label>
                        <PillSelector
                            pills={privacyPills}
                            selectedPill={facebookForm.privacy.name}
                            selectPill={onPrivacyChange}
                        />
                    </div>
                )}
            {selectedDestination.edge === "accounts" &&
            selectedStream === null ? (
                <div className="form-group">
                    <Toggle
                        label={t("comment-moderation")}
                        on={facebookForm.enableModeration}
                        // disabled={!canEdit}
                        onToggle={() => onToggle("enableModeration")}
                    />
                    {facebookForm.enableModeration && (
                        <div>
                            <MultiSelectWrapper
                                options={facebook.commentModerationOptions.map(
                                    (o) => ({
                                        value: o.id,
                                        label: `${o.name} - ${o.description}`,
                                        ...o
                                    })
                                )}
                                value={
                                    facebookForm.selectedCommentModerationOptions
                                }
                                onChange={(options) =>
                                    onSelect(
                                        "selectedCommentModerationOptions",
                                        options
                                    )
                                }
                                labelledBy="Comment moderation select"
                                disableSearch={true}
                                placeholder={t("comment-moderation-choose")}
                            />
                        </div>
                    )}
                </div>
            ) : null}
            {selectedStream === null && (
                <div className="form-group">
                    <Toggle
                        label={t("content-tags")}
                        on={enableContentTags}
                        // disabled={!canEdit}
                        onToggle={() =>
                            setEnableContentTags(!enableContentTags)
                        }
                    />
                    {enableContentTags && (
                        <MultiSelectWrapper
                            options={[]}
                            value={facebookForm.selectedTags as any}
                            onChange={(options) =>
                                onSelect("selectedTags", options.slice(0, 10))
                            }
                            labelledBy="Select content tags"
                            placeholder={t("content-tags-placeholder")}
                            onSearch={onSearch}
                            debounceDuration={1000}
                        />
                    )}
                </div>
            )}
            {user?.userInfo?.Roles?.some(
                (item) =>
                    [
                        "Professional",
                        "FbCreator",
                        "OrgAdmin",
                        "OrgContributor"
                    ].indexOf(item) !== -1
            ) ? (
                <div>
                    {selectedDestination.edge === "accounts" &&
                    selectedStream === null ? (
                        <div className="form-group">
                            <Toggle
                                label={`Crossposting`}
                                on={facebookForm.enableCrossposting}
                                // disabled={!canEdit}
                                onToggle={() => onToggle("enableCrossposting")}
                            />
                            {facebookForm.enableCrossposting && (
                                <MultiSelectWrapper
                                    options={crosspostingOptions.map((o) => ({
                                        value: o.id,
                                        label: `${o.name}`,
                                        name: o.name,
                                        id: o.id
                                    }))}
                                    value={
                                        facebookForm.selectedCrosspostingOptions as any
                                    }
                                    onChange={(options) =>
                                        onSelect(
                                            "selectedCrosspostingOptions",
                                            options
                                        )
                                    }
                                    labelledBy="Crossposting options"
                                    disableSearch={false}
                                    placeholder={t("crossposting-placeholder")}
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            ) : null}
            {brandedContentAllowed && selectedStream === null ? (
                <div className="form-group">
                    <Toggle
                        label={t("branded-content")}
                        on={facebookForm.enableBrandedContent}
                        // disabled={!canEdit}
                        onToggle={() => onToggle("enableBrandedContent")}
                    />
                    {facebookForm.enableBrandedContent ? (
                        <SearchableSelect
                            label={t("branded-content")}
                            placeholder={t("branded-content-placeholder")}
                            onSearch={(searchTerm) =>
                                facebookClient.pages.search(searchTerm)
                            }
                            resultRender={(item) =>
                                `${item.name} - ${new URL(item.link).pathname}`
                            }
                            onSelect={(option) => onBrandedSelect(option)}
                        />
                    ) : null}
                </div>
            ) : null}
            {selectedDestination.isTestStream ? (
                <div className={styles["test-disclaimer"]}>
                    {t("private-stream-notice")}
                </div>
            ) : null}
        </>
    );
};
