import {
    useParams as useReactRouterParams,
    useSearchParams as useReactRouterSearchParams
} from "react-router-dom";

const useReactRouterDom = import.meta.env.VITE_USE_REACT_ROUTER_DOM === "true";

export const useParams = <T extends object>(props: any): T => {
    const routerParams = (
        useReactRouterDom ? useReactRouterParams : () => () => {}
    )();
    const routerSearchParams = (
        useReactRouterDom ? useReactRouterSearchParams : () => () => {}
    )();

    if (!useReactRouterDom) {
        return props as T;
    } else {
        // this returns the params from useReactRouterParams()
        return { ...routerParams, ...routerSearchParams } as T;
    }
};
