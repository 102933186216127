import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useNavigate } from "hooks/useNavigate";
import { useTranslation } from "react-i18next";
import { tryGetProp } from "utils/js-utils";
import { CouponForm } from "components/forms/CouponForm";
import { StripePrice } from "@switcherstudio/switcher-api-client";
import styles from "./SelectPlanPage.module.scss";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { SelectPlanCardsContainer } from "components/cards/SelectPlanCardsContainer";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { useParams } from "hooks/useParams";

interface SelectPlanPageProps {
    props: any;
    hideSubscriptions?: string;
}

export const NewSelectPlanPage: React.FC = (planPageProps) => {
    const { props, hideSubscriptions: _hideSubscriptions } =
        useParams<SelectPlanPageProps>(planPageProps);
    const hideSubscriptions = useMemo(
        () => _hideSubscriptions?.toLowerCase() === "true",
        [_hideSubscriptions]
    );
    const { navigate } = useNavigate();
    const { t, i18n } = useTranslation("subscription");
    const user = useSelector((state: RootState) => state.user);
    const { claimedCoupon, addClaimedCoupon, removeClaimedCoupon } =
        useGetLastClaimedCoupon({
            couponCode: tryGetProp(props, "couponCode")
        });

    const { data: plans, loading } = useSwitcherClient(
        (client) => client.userStripePrices_GetStripePrices,
        {
            requestImmediately: true,
            args: [user.userInfo.UserId, i18n?.language?.slice(0, 2)]
        }
    );

    const isAppleSub = useMemo(() => {
        return (
            user.userInfo.ActiveProduct?.discriminator === "AppleProduct" &&
            user.userInfo.IsRecurring === true
        );
    }, [user.userInfo]);

    useRedirectIfDisallowed(() => !isAppleSub, "/subscription");

    const selectPlan = useCallback(
        (p: StripePrice) => {
            return navigate(`/subscription/subscribe?planId=${p.Id}`);
        },
        [navigate]
    );

    return (
        <>
            <div className="row">
                <div className="col">
                    <h1 className="text-center">{t("select-plan-header")}</h1>
                    <div className="row justify-content-center">
                        <div className=" col-lg-10 col-xl-8">
                            <p className="text-center">
                                {t("select-plan-sub-header")}{" "}
                                <a
                                    href="https://www.switcherstudio.com/pricing"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles["comparison-link"]}
                                >
                                    {t("select plan-sub-header-link-text")}
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className={styles["coupon-container"]}>
                        <CouponForm
                            className={styles["coupon-input"]}
                            userInfo={user.userInfo}
                            onClick={addClaimedCoupon}
                            removeCoupon={removeClaimedCoupon}
                            claimedCoupon={claimedCoupon}
                        />
                    </div>

                    <SelectPlanCardsContainer
                        plans={
                            hideSubscriptions
                                ? plans?.filter((p) => !p.IsRecurring)
                                : plans
                        }
                        loading={loading}
                        resellerInventoryItem={
                            claimedCoupon?.ResellerInventoryItem
                        }
                        onSelectPlanButtonClicked={selectPlan}
                        showFeatures
                        includePasses
                    />

                    <div className={styles["terms-container"]}>
                        <small className="text-muted">{t("fine-text")}</small>
                    </div>
                </div>
            </div>
        </>
    );
};
