import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import AppStreamDemo from "assets/app-demo-stream.png";
import AppMonetizeDemo from "assets/app-demo-monetize.png";
import AppIcon from "assets/AppIcon-1024x1024_new.png";
import ToAppStore from "assets/icons/to-app-store.svg?react";
import QR from "assets/icons/switcher-qr.png";
import { useTranslation } from "react-i18next";
import { isIOS } from "utils/js-utils";
import { useNavigate } from "hooks/useNavigate";
import { useOnboardingSurveyResults } from "hooks/useOnboardingSurveyResults";
import { Button } from "components/buttons/Button";

export const DownloadStep: React.FC = () => {
    const { t } = useTranslation("getting-started");
    const { results } = useOnboardingSurveyResults();

    const { navigate } = useNavigate();

    const toAppStore = useCallback(() => {
        window.open(
            "https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
        );
    }, []);

    const content = useMemo<string>(() => {
        if (!results) return "";

        if (results["options.monetize"]) {
            return t("getting-started:download-step.content-has-video");
        } else {
            return t("getting-started:download-step.content");
        }
    }, [results, t]);

    const prompt = useMemo<string>(() => {
        if (!results) return "";

        if (results["options.monetize"]) {
            return t("getting-started:download-step.prompt-monetize");
        } else if (
            results["options.embed"] ||
            results["video.ready-to-upload"]
        ) {
            return t("getting-started:download-step.prompt-embed");
        } else {
            return t("getting-started:download-step.prompt-stream");
        }
    }, [results, t]);

    const appDemoImage = useMemo<JSX.Element | undefined>(() => {
        if (!results) return <></>;

        if (results["options.monetize"]) {
            return (
                <img
                    src={AppMonetizeDemo}
                    className={styles["app-demo-1"]}
                    alt="App Demo"
                />
            );
        } else if (results["options.stream"]) {
            return (
                <img
                    src={AppStreamDemo}
                    className={styles["app-demo-2"]}
                    alt="App Demo"
                />
            );
        } else {
            return undefined;
        }
    }, [results]);

    return (
        <>
            <img
                src={AppIcon}
                alt="Switcher App Icon"
                className={styles["app-icon"]}
            />

            <div className="row justify-content-center m-0">
                <div className="text-center">
                    <h4 className={`${styles["title-light"]}`}>
                        {t("getting-started:download-step.title1")}{" "}
                        <strong>
                            {t("getting-started:download-step.title2")}
                        </strong>
                    </h4>
                    <div className={styles["paragraph"]}>
                        <span>{content}</span>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3 ">
                    <div className={`${styles["apps-container"]} mb-4`}>
                        {appDemoImage}
                        <div
                            className={
                                !appDemoImage ? styles["side-by-side"] : ""
                            }
                        >
                            {!isIOS() && (
                                <img
                                    src={QR}
                                    className={styles["qr-code"]}
                                    alt="QR code to App Store"
                                    aria-hidden="true"
                                />
                            )}
                            <ToAppStore
                                onClick={toAppStore}
                                className={styles["to-app-store"]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles["continue"]}>
                <strong>{prompt}</strong>
                <Button onClick={() => navigate("/home")}>
                    {t("getting-started:download-step.link")}
                </Button>
            </div>
        </>
    );
};
