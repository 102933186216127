import { Banner } from "components/banners/Banner";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import { usePageBanner } from "hooks/usePageBanner";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "hooks/useNavigate";

export const useCatalogAccessBanner = () => {
    const { updatePageBanner } = usePageBanner();
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    const isStudioUser = useHasPlanRole("Studio");
    const { t } = useTranslation();
    const { navigate } = useNavigate();

    /** Updated page banner with video player disabled banner */
    useEffect(() => {
        if (videoPlayerDisabled) {
            updatePageBanner(
                <Banner
                    header={t("players:messages:players-disabled-header")}
                    subheader={t("players:messages:catalog-disabled-subheader")}
                    buttonProps={{
                        type: "btn-outline-primary",
                        label: t("buttons:contact-sales"),
                        onClick: () => {
                            navigate(`/billing`);
                        },
                        className: "upgrade-button"
                    }}
                    cssClass="switcher-player-studio-promo"
                />
            );
        } else if (isStudioUser) {
            updatePageBanner(
                <Banner
                    header={t("promotion:studio-switcher-player:demo-catalog")}
                    subheader={t("promotion:studio-switcher-player:upgrade")}
                    buttonProps={{
                        type: "btn-outline-primary",
                        label: t("buttons:upgrade-plan").toUpperCase(),
                        onClick: () => {
                            navigate(`/billing`);
                        },
                        className: "upgrade-button"
                    }}
                    cssClass="switcher-player-studio-promo"
                />
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoPlayerDisabled, isStudioUser, t]);
};
