import {
    loadStripe,
    PaymentIntent,
    SetupIntent,
    StripeError
} from "@stripe/stripe-js";

import { removeQueryParams } from "helpers/url";
import { useState } from "react";
import { useRunOnce } from "./useRunOnce";

export interface RedirectCallbackArguments {
    redirectStatus: string;
}

export interface useBillingRedirectOptions {
    onPaymentIntent?: (
        paymentIntent: PaymentIntent,
        args: RedirectCallbackArguments
    ) => void;
    onSetupIntent?: (
        setupIntent: SetupIntent,
        args: RedirectCallbackArguments
    ) => void;
    onError?: (error: StripeError) => void;
}

export const useBillingRedirect = ({
    onPaymentIntent,
    onSetupIntent,
    onError
}: useBillingRedirectOptions = {}) => {
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
    const [setupIntent, setSetupIntent] = useState<SetupIntent>();
    const [redirectStatus, setRedirectStatus] = useState<string>();

    useRunOnce(async () => {
        try {
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_API_KEY
            );

            const searchParams = new URLSearchParams(window.location.search)
            const paymentIntentClientSecret = searchParams.get("payment_intent_client_secret");
            const setupIntentClientSecret = searchParams.get("setup_intent_client_secret");
            const redirectStatus = searchParams.get("redirect_status");

            setRedirectStatus(redirectStatus);

            if (paymentIntentClientSecret) {
                const { paymentIntent, error } =
                    await stripe.retrievePaymentIntent(
                        paymentIntentClientSecret
                    );

                if (error) throw error;

                if (!paymentIntent) {
                    return;
                }

                setPaymentIntent(paymentIntent);
                onPaymentIntent?.(paymentIntent, { redirectStatus });
            }

            if (setupIntentClientSecret) {
                const { setupIntent, error } = await stripe.retrieveSetupIntent(
                    setupIntentClientSecret
                );

                if (error) throw error;

                if (!setupIntent) {
                    return;
                }

                setSetupIntent(setupIntent);
                onSetupIntent?.(setupIntent, { redirectStatus });
            }
        } catch (error) {
            onError?.(error as StripeError);
        }
        removeQueryParams();
    });
    return { paymentIntent, setupIntent, redirectStatus };
};
