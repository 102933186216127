import {
    CouponResponse,
    ProrationResponse,
    SilverSunnStripeCreditCard,
    SilverSunnStripeCustomer,
    StripeInvoiceBindingModel,
    StripePrice
} from "@switcherstudio/switcher-api-client";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useStripeHandlers } from "./useStripeHandlers";
import rollbar from "helpers/rollbar";
import { t } from "i18next";
import { useSwitcherClient } from "./useSwitcherClient";
import { createUrl } from "helpers/url";
import { stringifyBool } from "helpers/booleans";

export interface useCheckoutOptions {
    proration: ProrationResponse;
    invoice: StripeInvoiceBindingModel;
    selectedPaymentMethodId: string;
    claimedCoupon: CouponResponse;
    customer: SilverSunnStripeCustomer;
    plan: StripePrice;
    onSuccess?: () => void;
    onError?: (message: string) => void;
}

export const useCheckout = ({
    proration,
    invoice,
    selectedPaymentMethodId,
    claimedCoupon,
    customer,
    plan,
    onSuccess,
    onError
}: useCheckoutOptions) => {
    const { stripe, elements } = useStripeHandlers();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string>("");

    const isSubscription = useMemo<boolean | null>(
        () => plan?.IsRecurring,
        [plan]
    );

    const availablePaymentMethods = useMemo<SilverSunnStripeCreditCard[]>(
        () => customer?.StripeCreditCards,
        [customer?.StripeCreditCards]
    );

    const licensedSubscription = useMemo(
        () =>
            customer?.StripeSubscriptions?.find(
                (s) => s.UsageType === "licensed"
            ),
        [customer]
    );

    const isTrialing = useMemo<boolean>(
        () => userInfo.Status === "trialing",
        [userInfo]
    );

    const isNewSubscription = useMemo(
        () => !licensedSubscription,
        [licensedSubscription]
    );

    const currentSubscriptionId = useMemo(
        () => licensedSubscription?.SilverSunnStripeSubscriptionId,
        [licensedSubscription]
    );

    const returnUrl = useMemo<string>(
        () =>
            createUrl(window.location.origin, {
                pathname: "/subscription",
                searchParams: {
                    planId: plan?.Id,
                    isSubscription: stringifyBool(isSubscription),
                    isTrialing: stringifyBool(isTrialing),
                    isNewSubscription: stringifyBool(isNewSubscription),
                    currentSubscriptionId,
                    invoiceId: invoice?.id,
                    resellerInventoryItemId:
                        claimedCoupon?.ResellerInventoryItem.Id
                }
            }).toString(),
        [
            claimedCoupon?.ResellerInventoryItem.Id,
            currentSubscriptionId,
            invoice,
            isNewSubscription,
            isSubscription,
            isTrialing,
            plan?.Id
        ]
    );

    const { dispatchApiRequest: getPaymentIntent } = useSwitcherClient(
        (client) => client.stripeIntents_GetPaymentIntent,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: createSubscription } = useSwitcherClient(
        (client) => client.userSubscriptions_CreateSubscription,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: updateSubscription } = useSwitcherClient(
        (client) => client.userSubscriptions_UpdateSubscription,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: setPrimaryPaymentMethod } = useSwitcherClient(
        (client) => client.userPaymentMethods_SetPrimaryPaymentMethod,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: deletePaymentMethod } = useSwitcherClient(
        (client) => client.userPaymentMethods_DeletePaymentMethod,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const makePayment = useCallback(
        async (paymentMethodId: string): Promise<void> => {
            if (!paymentMethodId) {
                throw new Error(t("errors:no-payment-method"));
            }

            // const retryStatus = await retryInvoice();

            const amount: number = isSubscription
                ? proration.AmountDue
                : invoice.amount_due;

            // Check if charge is greater then the Stipe minimum charge
            if (amount > 50) {
                const { ClientSecret } = await getPaymentIntent([
                    {
                        Items: [
                            {
                                Id: plan?.Id,
                                Amount: amount
                            }
                        ]
                    }
                ]);

                if (paymentMethodId === "new-payment-method") {
                    const { error } = await stripe.confirmPayment({
                        elements,
                        clientSecret: ClientSecret,
                        confirmParams: {
                            return_url: returnUrl
                        },
                        redirect: "always"
                    });

                    if (error) {
                        throw error;
                    }
                } else {
                    const { error } = await stripe.confirmPayment({
                        clientSecret: ClientSecret,
                        confirmParams: {
                            payment_method: paymentMethodId,
                            return_url: returnUrl
                        }
                    });

                    if (error) {
                        throw error;
                    }
                }
            } else if (paymentMethodId === "new-payment-method") {
                const { error } = await stripe.confirmSetup({
                    elements,
                    redirect: "always",
                    confirmParams: {
                        return_url: returnUrl
                    }
                });

                throw error;
                // create an invoice for $0 to track plan changes
            } else {
                if (isNewSubscription) {
                    // create new subscription - this is called if the user
                    // has NO subscription, which is possible if their previous
                    // subscription has been canceled and the cancellation date
                    // has passed

                    await createSubscription([
                        userInfo.UserId,
                        {
                            OrganizationId: userInfo.OrganizationId,
                            ResellerInventoryItemId:
                                claimedCoupon.ResellerInventoryItem.Id,
                            Plan: plan.Id,
                            Quantity: 1,
                            TrialDays: 0,
                            Source: "SilverSunnDashboard"
                        }
                    ]);
                } else {
                    // update user's current subscription
                    await updateSubscription([
                        userInfo.UserId,
                        currentSubscriptionId,
                        {
                            OrganizationId: userInfo.OrganizationId,
                            ResellerInventoryItemId:
                                claimedCoupon?.ResellerInventoryItem.Id,
                            Plan: plan?.Id
                        }
                    ]);
                }

                onSuccess?.();
            }
        },
        [
            isSubscription,
            proration,
            invoice,
            getPaymentIntent,
            plan,
            stripe,
            elements,
            returnUrl,
            isNewSubscription,
            onSuccess,
            createSubscription,
            userInfo,
            claimedCoupon,
            updateSubscription,
            currentSubscriptionId
        ]
    );

    const checkout = useCallback(async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsSubmitting(true);
        setPaymentError("");

        try {
            elements.submit();

            await makePayment(selectedPaymentMethodId);
        } catch (e) {
            // If a new payment method is used, try to reset the user's default
            // payment method and delete the new failed one.
            if (selectedPaymentMethodId) {
                try {
                    if (availablePaymentMethods.length) {
                        const originalDefault = availablePaymentMethods.find(
                            (c) => c.Default && !c.Expired
                        );
                        if (originalDefault) {
                            await setPrimaryPaymentMethod([
                                userInfo.UserId,
                                originalDefault.SilverSunnStripeCreditCardId
                            ]);
                        }
                    }

                    await deletePaymentMethod([
                        userInfo.UserId,
                        selectedPaymentMethodId
                    ]);
                } catch {
                    /** eat DeletePaymentMethod request errors */
                }
            }

            if (!!e?.error?.decline_code) {
                rollbar.warning(
                    "Payment collection error with decline code",
                    e
                );
            } else {
                rollbar.error(
                    "Payment collection error without decline code",
                    e
                );
            }

            setPaymentError(e.message || e.error.decline_code);
            onError?.(e.message || e.error.decline_code);
        } finally {
            setIsSubmitting(false);
        }
    }, [
        stripe,
        elements,
        makePayment,
        selectedPaymentMethodId,
        onError,
        availablePaymentMethods,
        deletePaymentMethod,
        userInfo.UserId,
        setPrimaryPaymentMethod
    ]);

    return { checkout, isSubmitting, paymentError };
};
