import { exists } from "helpers/booleans";
import { useGetPromotionalClaim } from "hooks/useGetPromotionalClaim";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setHasViewedPromoModal } from "store/promotions/slice";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import { useNavigate } from "hooks/useNavigate";

/**
 * A modal to display information about a promotion the user is currently experiencing
 * The modal will only display information regarding the first promo a user is eligible for.
 * (the user cannot see more than one promo modal at any given time)
 */
export const PromoModal = () => {
    const {
        eligiblePromotionName,
        eligibleUpgradeCoupon,
        hasPromotionalClaim,
        promoModalProps: {
            hasViewedModal,
            Header,
            SubHeader,
            Description,
            DescriptionTwo,
            ButtonText,
            VideoUrl
        }
    } = useGetPromotionalClaim();
    const dispatch = useDispatch();
    const handleClose = useCallback(() => {
        dispatch(
            setHasViewedPromoModal({
                promoName: eligiblePromotionName,
                hasViewedModal: true
            })
        );
    }, [dispatch, eligiblePromotionName]);
    const { navigate } = useNavigate();
    const useNewBillingPage =
        import.meta.env.VITE_USE_NEW_BILLING_PAGE === "true";

    const onButtonClicked = useCallback(() => {
        handleClose();
        navigate(
            useNewBillingPage
                ? `/subscription${
                      exists(eligibleUpgradeCoupon)
                          ? `?couponCode=${eligibleUpgradeCoupon}`
                          : ""
                  }`
                : `/subscription/subscribe${
                      exists(eligibleUpgradeCoupon)
                          ? `?couponCode=${eligibleUpgradeCoupon}`
                          : ""
                  }`
        );
    }, [navigate, eligibleUpgradeCoupon, handleClose, useNewBillingPage]);

    return (
        hasPromotionalClaim && (
            <ModalBase
                isOpen={!hasViewedModal}
                setIsOpen={handleClose}
                size="small"
                stylesOverride={{
                    padding: 0
                }}
                preventDismiss={false}
            >
                <div className={styles["modal-content"]}>
                    <div
                        style={{
                            position: "relative",
                            paddingTop: "56.25%"
                        }}
                    >
                        <iframe
                            title="promo"
                            src={VideoUrl}
                            style={{
                                border: "none",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%"
                            }}
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className={styles["promo-details"]}>
                        <h3 className={styles["header"]}>{Header}</h3>
                        {exists(SubHeader) && (
                            <h3 className={styles["sub-header"]}>
                                {SubHeader}
                            </h3>
                        )}
                        <hr className={styles["red-dash"]} />
                        <p>{Description}</p>
                        {exists(DescriptionTwo) && <p>{DescriptionTwo}</p>}
                        <button
                            type="button"
                            onClick={onButtonClicked}
                            className="btn btn-primary"
                        >
                            {ButtonText}
                        </button>
                    </div>
                </div>
            </ModalBase>
        )
    );
};
