import React, { useState, useMemo } from "react";
import { StripeInvoiceBindingModel } from "@switcherstudio/switcher-api-client";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useDateRangeSelector } from "hooks/useDateRangeSelector";
import { useSwitcherClient } from "hooks/useSwitcherClient";

enum SubscriptionDateRangeOptions {
    ThirtyDays = "thirtyDays",
    MonthToDate = "monthToDate",
    YearToDate = "yearToDate",
    TwelveMonths = "twelveMonths",
    Custom = "custom"
}
interface InvoiceTableProps {
    displayDateFn: (date?: string) => string | undefined;
    displayCostFn: (amount?: number) => string | undefined;
}

export const InvoiceTableV2: React.FC<InvoiceTableProps> = ({
    displayDateFn,
    displayCostFn
}) => {
    const { t, i18n } = useTranslation();
    const [selectedTimeFrame, setSelectedTimeFrame] =
        useState<SubscriptionDateRangeOptions>(
            SubscriptionDateRangeOptions.TwelveMonths
        );

    const { initialStart, initialEnd } = useMemo(() => {
        const now = () => new Date();
        return {
            initialStart: new Date(now().setFullYear(now().getFullYear() - 1)),
            initialEnd: now()
        };
    }, []);

    const [customStartDate, setCustomStartDate] = useState<Date | null>(
        initialStart
    );
    const [customEndDate, setCustomEndDate] = useState<Date | null>(initialEnd);

    function invoiceIsCredit(invoice: StripeInvoiceBindingModel) {
        const displayedPrice =
            invoice.applied_balance < 0 ? invoice.amount_due : invoice.total;

        return displayedPrice >= 0 ? false : true;
    }

    const {
        start,
        end,
        component: DateRangeSelectorComponent
    } = useDateRangeSelector({
        id: "invoice-date-range",
        label: t("subscription:date-range-label"),
        selection:
            selectedTimeFrame ?? SubscriptionDateRangeOptions.TwelveMonths,
        options: Object.values(SubscriptionDateRangeOptions).map((value) => ({
            value,
            text: t(`date-range-options:${value}`)
        })),
        onChange: (e) => {
            setSelectedTimeFrame(
                e.target.value as SubscriptionDateRangeOptions
            );
        },

        customStartDate: customStartDate,
        onStartDateChange: (date) => setCustomStartDate(date),
        placeholderStartDate: initialStart,
        customEndDate: customEndDate,
        onEndDateChange: (date) => setCustomEndDate(date),
        placeholderEndDate: initialEnd,
        datePickerProps: {
            showTimeSelect: false,
            locale: i18n.language
        }
    });

    const { data: invoices } = useSwitcherClient(
        (client) => client.stripe_GetInvoices,
        {
            requestImmediately: true,
            args: [start, end]
        }
    );

    return (
        <div className={styles["invoice-table"]}>
            {invoices && (
                <>
                    {!invoices || invoices.length === 0 ? (
                        <div className="alert alert-info" role="alert">
                            {t("messages:no-invoices")}
                        </div>
                    ) : null}
                    <header className={styles["invoice-header"]}>
                        <h4>{t("subscription:invoice-history")}</h4>
                        {DateRangeSelectorComponent}
                    </header>
                    <table>
                        <thead>
                            <tr>
                                <th>{t("subscription:invoice-date")}</th>
                                <th>{t("subscription:total")}</th>
                                <th>{t("subscription:status")}</th>
                                <th>{t("misc:action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices?.map((invoice, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{displayDateFn(invoice.date)}</td>
                                        <td>
                                            {displayCostFn(
                                                invoice.applied_balance < 0
                                                    ? invoice.amount_due
                                                    : invoice.total
                                            )}
                                        </td>
                                        <td>
                                            {invoiceIsCredit(invoice)
                                                ? t("subscription:credit")
                                                : t("subscription:paid")}
                                        </td>
                                        <td>
                                            {invoice.paid && (
                                                <a
                                                    className={`${styles["table-action"]}`}
                                                    href={
                                                        invoice.hosted_invoice_url
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t("messages:view")}
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};
