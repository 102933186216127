import { useCallback } from "react";
import rollbar from "helpers/rollbar";
import { useDispatch } from "react-redux";
import { openConfirmation } from "store/confirmation/slice";
import { useTranslation } from "react-i18next";
import { trackEvent } from "helpers/analyticsHelpers";
import { useSwitcherClient } from "./useSwitcherClient";
import { useNavigate } from "hooks/useNavigate";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";

export const useCancelSubscription = (
    subscriptionId: string,
    customerId: string
) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { navigate } = useNavigate();

    const { dispatchApiRequest: deleteSubscription, loading: cancelLoading } =
        useSwitcherClient((client) => client.stripe_DeleteSubscription, {
            requestImmediately: false,
            args: [subscriptionId]
        });

    const cancelSub = useCallback(async () => {
        try {
            await deleteSubscription();
            navigate("/subscription");
        } catch (e) {
            rollbar.error("Error canceling user subscription", e);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: e?.message
                })
            );
        }
    }, [dispatch, navigate, deleteSubscription]);

    const initiateProfitwellRetainAttempt = useCallback(
        async (subscriptionId: string) => {
            try {
                const profitwellResponse = await profitwell(
                    "init_cancellation_flow",
                    { subscription_id: subscriptionId }
                );
                if (profitwellResponse) {
                    trackEvent("ProfitWell Retain Attempt", {
                        profitWellResponse: profitwellResponse,
                        subscriptionId,
                        customerId
                    });
                }

                if (!profitwellResponse) {
                    await dispatch(
                        openConfirmation({
                            message: t("messages:cancel-confirmation"),
                            onSuccess: () => cancelSub()
                        })
                    );
                } else if (
                    profitwellResponse.status === "retained" ||
                    profitwellResponse.status === "aborted"
                ) {
                    return;
                } else {
                    await cancelSub();
                }
            } catch (e) {
                await dispatch(
                    openConfirmation({
                        message: t("messages:cancel-confirmation"),
                        onSuccess: () => cancelSub()
                    })
                );
            }
        },
        [dispatch, cancelSub, customerId, t]
    );
    return {
        /** attempt to retain customer with coupons thru profitwell and ultimately cancel subscription */
        initiateProfitwellRetainAttempt,
        /** the attempt to cancel the subscription is loading */
        cancelLoading
    };
};
