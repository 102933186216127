import styles from "./index.module.scss";
import { VideoUploadModalEntryPoint } from "components/video-upload/VideoUploadModalEntryPoint";
import { Link } from "components/buttons/Link";
import { openExternalUrlInNewTab } from "helpers/navigation";
import { AddVideosContentProps } from "./types";
import { Spinner } from "components/spinners/Spinner";

export const AddVideosContent = ({
    isVideoLibrary,
    hasVideos,
    refetchVideos,
    loading,
    GenericMultiSelectComponent,
    t,
    userHasNoSubscription,
    videoUploadSessionId,
    setVideoUploadSessionId,
    playerId,
    onClose,
    uploadModalKey
}: AddVideosContentProps) => {
    return (
        <>
            {isVideoLibrary && userHasNoSubscription ? (
                <></>
            ) : (
                <div
                    className={
                        isVideoLibrary
                            ? styles["upload-drag-and-drop-container"]
                            : styles["upload-container"]
                    }
                >
                    <VideoUploadModalEntryPoint
                        sessionId={videoUploadSessionId}
                        setSessionId={setVideoUploadSessionId}
                        allowMultipleUploads
                        lockedToPlayer={isVideoLibrary ? undefined : playerId}
                        onUploadComplete={
                            isVideoLibrary
                                ? () =>
                                      refetchVideos(null, { hideLoading: true })
                                : onClose
                        }
                        isVideoLibrary={isVideoLibrary}
                        showVideoStorageBar={isVideoLibrary}
                        refreshKey={uploadModalKey}
                    />
                </div>
            )}
            {loading ? (
                <div className={styles["loading"]}>
                    <Spinner size={128} />
                </div>
            ) : hasVideos ? (
                <>
                    {!isVideoLibrary && (
                        <div className={styles["separator"]}>
                            <hr />
                            <small>
                                {t("video-player-page:add-videos-prompt")}
                            </small>
                            <hr />
                        </div>
                    )}
                    <>{GenericMultiSelectComponent}</>
                </>
            ) : (
                <p>
                    {t("video-library:no-videos")}{" "}
                    <Link
                        onClick={() =>
                            openExternalUrlInNewTab(
                                "https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                            )
                        }
                    >
                        {t("video-library:download-app")}
                    </Link>
                </p>
            )}
        </>
    );
};
