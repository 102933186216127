import React, { useEffect, useState } from "react";
import { client } from "api/client";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { PlatformId } from "../types";
import { useNavigate } from "hooks/useNavigate";
import { IframePlatformContainer } from "./iframe-platform/IframePlatformContainer";
import rollbar from "helpers/rollbar";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useParams } from "hooks/useParams";

interface IframePlatformPageProps {
    platformId: PlatformId;
}

export const IframePlatformPage: React.FC = (props) => {
    const { platformId } = useParams<IframePlatformPageProps>(props);
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch<AppDispatch>();
    const { navigate } = useNavigate();

    const [platform, setPlatform] = useState<StreamingProvider>();

    useEffect(() => {
        async function run() {
            try {
                const response =
                    await client.streamingProviders_GetStreamingProvider(
                        platformId
                    );
                if (isMountedRef.current) {
                    setPlatform(response);
                }
            } catch (e) {
                rollbar.error("Error getting streaming provider", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );
            }
        }

        if (isMountedRef.current) {
            if (!platform) {
                run();
            }
        }
    }, [isMountedRef, navigate, platform, platformId, dispatch]);

    return <>{platform && <IframePlatformContainer platform={platform} />}</>;
};
