import {
    Entitlement,
    SilverSunnStripeCustomer
} from "@switcherstudio/switcher-api-client";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { SubscriptionWithPrice } from "./";
import { es, enUS } from "date-fns/locale";
import { PaymentBrandIcon } from "components/icons/PaymentBrandIcon";
import { Widget } from "components/widgets/Widget";
import { ManagePaymentDetails } from "components/modal/ManagePaymentDetails";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { SetupIntentElement } from "components/stripe/SetupIntentElement";
import { SetupIntent } from "@stripe/stripe-js";
import { RedirectCallbackArguments } from "hooks/useBillingRedirect";
import { trackEvent } from "helpers/analyticsHelpers";
import rollbar from "helpers/rollbar";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";

export const NextPaymentOnV2 = ({
    customer,
    refetchCustomer,
    activeEntitlement,
    activePlan: { subscription: activeSubscriptionPlan } = {
        subscription: undefined,
        price: undefined
    }
}: {
    customer: SilverSunnStripeCustomer;
    refetchCustomer: () => void;
    activeEntitlement: Entitlement;
    activePlan?: SubscriptionWithPrice;
}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { userInfo } = useSelector((state: RootState) => state.user);

    const { dispatchApiRequest: attachPaymentMethod } = useSwitcherClient(
        (client) => client.userPaymentMethods_AttachPaymentMethod,
        {
            onError: (e) => {
                rollbar.error("Error adding payment method", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "errors:payment-add-error"
                    })
                );
            },
            onSuccess: () => {
                trackEvent("Added Payment Info", null, {
                    integrations: { Intercom: false }
                });
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:payment-add-success"
                    })
                );
            }
        }
    );

    const onSetupIntentRedirect = useCallback(
        async (
            { payment_method, status }: SetupIntent,
            { redirectStatus }: RedirectCallbackArguments
        ) => {
            if (redirectStatus === "succeeded" && status === "succeeded")
                await attachPaymentMethod([
                    userInfo.UserId,
                    typeof payment_method === "string"
                        ? payment_method
                        : payment_method.id
                ]);
            refetchCustomer();
        },
        [attachPaymentMethod, refetchCustomer, userInfo.UserId]
    );

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const defaultCreditCard = useMemo(
        () => customer?.StripeCreditCards?.find((c) => c.Default === true),
        [customer]
    );

    const cardDescriptionComponent = useMemo(() => {
        if (!defaultCreditCard) {
            return <></>;
        }

        const { Brand, LastFour } = defaultCreditCard;
        const description = `${Brand} ${t("misc:ending-in")} ${LastFour}`;
        const brandIcon = <PaymentBrandIcon brandSlug={Brand} />;

        return (
            <>
                {brandIcon}
                {description}
            </>
        );
    }, [defaultCreditCard, t]);

    const nextPaymentNode = useMemo(() => {
        if (!activeEntitlement && !activeSubscriptionPlan) return <></>;

        let nextPaymentString = "";
        let renewalText = "";

        if (!activeSubscriptionPlan) {
            renewalText = t("subscription:active-until");
            nextPaymentString = activeEntitlement?.ExpiresAt
                ? format(
                      new Date(activeEntitlement.ExpiresAt),
                      "EEEE, MMMM d, yyyy",
                      { locale: i18n.language === "es" ? es : enUS }
                  )
                : "";
        } else {
            // Determine the renewal text based on the subscription status
            const { CancelAtPeriodEnd, CanceledAt, TrialUntil, ActiveUntil } =
                activeSubscriptionPlan;

            renewalText = `${t("subscription:next-charge")}: `;

            if (TrialUntil === ActiveUntil) {
                renewalText = `${t("subscription:trial-ends")}: `;
            }
            if (CancelAtPeriodEnd || !!CanceledAt) {
                renewalText = `${t("subscription:cancellation")}: `;
            }

            nextPaymentString = `${format(
                new Date(activeSubscriptionPlan.ActiveUntil),
                "EEEE, MMMM d, yyyy",
                { locale: i18n.language === "es" ? es : enUS }
            )}`;
        }

        return (
            <p>
                <span>{renewalText}</span>
                {nextPaymentString}
            </p>
        );
    }, [activeEntitlement, activeSubscriptionPlan, i18n, t]);

    return (
        <>
            <Widget
                title={t("subscription-page:payment-details").toUpperCase()}
                details={[
                    {
                        title: cardDescriptionComponent,
                        children: <>{nextPaymentNode}</>
                    }
                ]}
                button={
                    <button className="btn" onClick={() => setModalOpen(true)}>
                        {t("subscription-page:update-payment-method")}
                    </button>
                }
                variant="billing"
            />
            <SetupIntentElement onRedirect={onSetupIntentRedirect}>
                {modalOpen && (
                    <ManagePaymentDetails
                        customer={customer}
                        isOpen={modalOpen}
                        setIsOpen={setModalOpen}
                        onUpdate={refetchCustomer}
                    />
                )}
            </SetupIntentElement>
        </>
    );
};
