import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import Logo from "assets/icons/switcherLogoV.png";
import { Link } from "react-navi";
import { useHasAccess } from "hooks/useHasAccess";

const useNewBillingPage = import.meta.env.VITE_USE_NEW_BILLING_PAGE === "true";

export const DisabledFeature: React.FC = () => {
    const isPaused = useHasAccess((userInfo) =>
        userInfo.Roles.includes("Paused")
    );
    const { t } = useTranslation();

    return (
        <div className={styles["disabled-container"]}>
            <div className={styles["logo-container"]}>
                <img className="img-fluid" src={Logo} alt="Switcher Logo" />
            </div>
            <div className={styles["warning-text"]}>
                {t("misc:disabled-feature")}
            </div>
            <Link
                href={`${
                    isPaused
                        ? "#"
                        : useNewBillingPage
                        ? "/subscription#openModal"
                        : "/subscription/subscribe"
                }`}
                className={`btn ${
                    isPaused ? "btn-outline-secondary disabled" : "btn-primary"
                }`}
                aria-disabled={isPaused}
            >
                {t("buttons:upgrade-account")}
            </Link>
        </div>
    );
};
