import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { useStripeAccountInfo } from "hooks/useStripeAccountInfo";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";
import { RecurringPassField } from "components/modal/GatedContentModal/RecurringPassField";
import { PricingModalTypes } from "components/modal/GatedContentModal";
import { StripeConnectCard } from "components/cards/StripeConnectButton";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { AppDispatch } from "store/store";
import { postEvents } from "store/events/thunks";
import rollbar from "helpers/rollbar";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    buildPriceCreateObj,
    buildPricesArray
} from "components/modal/GatedContentModal/helpers";
import { v4 as uuidv4 } from "uuid";
import { displayAmount } from "helpers/stripe";
import { GatedContentPassFormProps } from "components/modal/GatedContentModal";
import {
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductPricesBindingModel
} from "@switcherstudio/switcher-api-client";
import { RootState } from "store/reducers";
import { useGetStripeAccounts } from "hooks/useGetStripeAccounts";
import { exists } from "helpers/booleans";
import { sortByDate } from "helpers/time";
import { useCatalogData } from "hooks/useCatalogData";
import type { StringDictionary } from "types";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { AttentionModal } from "components/modal/AttentionModal";

export const CatalogSubscriptionPage: React.FC = () => {
    useCatalogAccessBanner();
    const { t } = useTranslation("subscription-page");
    const dispatch = useDispatch<AppDispatch>();
    const userInfo = useSelector((s: RootState) => s.user?.userInfo);
    const [priceErrors, setPriceErrors] = useState<StringDictionary>({});

    usePageHeader({
        title: t("subscription-page:subscription-options"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("subscription-page:subscription-options")
        ],
        subTitle: (
            <Trans
                i18nKey={t("subscription-page:subscription-options-subtitle")}
            />
        )
    });

    // Get the Stripe info
    const { details, gatedContentStatus, loading } = useStripeAccountInfo({
        expandDetails: true
    });
    const { accounts } = useGetStripeAccounts();
    const { isSetup } = useGetStripeConnectLink();

    // Get the catalog data
    const { catalogData: catalog, loading: catalogLoading } = useCatalogData({
        projectId: userInfo?.ProjectId
    });

    /* Define (alias) dispatchApiRequest callbacks for API calls */

    // POST Products
    const { dispatchApiRequest: postProduct } = useSwitcherClient(
        (client) => client.creatorProducts_Create
    );

    // POST Prices
    const { dispatchApiRequest: postProductPrices } = useSwitcherClient(
        (client) => client.creatorProductPrices_Create
    );

    // PUT Prices
    const { dispatchApiRequest: putProductPrices } = useSwitcherClient(
        (client) => client.creatorProductPrices_Update
    );

    // POST Entitlements
    const { dispatchApiRequest: postEntitlement } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_Create
    );

    // GET Entitlements and Prices by Catalog ID (to refetch after updates)
    const {
        dispatchApiRequest: getEntitlementAndPrices,
        data: entitlementAndPrices
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_GetByCatalogId,
        {
            requestImmediately: true,
            args: [catalog?.Details?.Id, true]
        }
    );

    // Remove all active subscriptions
    const { dispatchApiRequest: removeActiveSubscriptions } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_CancelByProductId,
        {
            requestImmediately: false,
            args: [entitlementAndPrices?.ProductEntitlements?.[0]?.ProductId],
            onError: (e) => {
                rollbar.error("Error removing active subscriptions", e, {
                    productId:
                        entitlementAndPrices?.ProductEntitlements?.[0]
                            ?.ProductId
                });
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("catalog-cancellation-error")
                    })
                );
            }
        }
    );

    // Manage state for local form values
    const [createOrUpdate, setCreateOrUpdate] = useState<PricingModalTypes>(
        PricingModalTypes.Create
    );
    const [values, setValues] = useState<GatedContentPassFormProps>(null);
    const [oldProduct, setOldProduct] = useState<any>(null);
    const [oldRecurringMonthlyPrice, setOldRecurringMonthlyPrice] =
        useState<any>(null);
    const [oldRecurringAnnualPrice, setOldRecurringAnnualPrice] =
        useState<any>(null);

    const format = (amount: number) => {
        return displayAmount(amount, {
            signed: false,
            compact: false,
            roundUp: false,
            useGrouping: false
        });
    };

    useEffect(() => {
        if (!entitlementAndPrices) return;
        const oldProduct =
            entitlementAndPrices?.ProductEntitlements[0]?.Product;

        // Get the most recent values for recurring monthly and annual prices
        const oldRecurringMonthlyPrice = oldProduct?.Prices?.filter(
            (p) => p.RecurringInterval === "month"
        )?.sort((a, b) =>
            sortByDate(a.InsertedAt, b.InsertedAt, { descending: true })
        )?.[0];

        const oldRecurringAnnualPrice = oldProduct?.Prices?.filter(
            (p) => p.RecurringInterval === "year"
        )?.sort((a, b) =>
            sortByDate(a.InsertedAt, b.InsertedAt, { descending: true })
        )?.[0];

        // Set local state
        if (exists(oldProduct)) setCreateOrUpdate(PricingModalTypes.Update);
        setOldProduct(oldProduct);
        setOldRecurringMonthlyPrice(oldRecurringMonthlyPrice);
        setOldRecurringAnnualPrice(oldRecurringAnnualPrice);

        // Set the values for the form
        const isMonthlyActive = oldRecurringMonthlyPrice?.Active || false;
        const isAnnualActive = oldRecurringAnnualPrice?.Active || false;
        const recurringMonthlyPrice = oldRecurringMonthlyPrice
            ? format(oldRecurringMonthlyPrice?.Amount)
            : "10.00";
        const recurringAnnualPrice = oldRecurringAnnualPrice
            ? format(oldRecurringAnnualPrice?.Amount)
            : "100.00";

        const newValues: GatedContentPassFormProps = {
            name: "Catalog Pass",
            description: "",
            selectedPassType: "recurring",
            recurringMonthlyPriceSelected: isMonthlyActive,
            recurringAnnualPriceSelected: isAnnualActive,
            oneTimePrice: "",
            recurringMonthlyPrice,
            recurringAnnualPrice,
            recurringApplySubscriptionUpdatesNewOnly: true
        };

        setValues(newValues);
    }, [entitlementAndPrices]);

    const recurringPassHasNoChanges = useMemo(() => {
        const monthlyActiveMatch =
            oldRecurringMonthlyPrice?.Active ===
            values?.recurringMonthlyPriceSelected;
        const monthlyAmountMatch =
            oldRecurringMonthlyPrice?.Amount ===
            parseFloat(values?.recurringMonthlyPrice) * 100;
        const annualActiveMatch =
            oldRecurringAnnualPrice?.Active ===
            values?.recurringAnnualPriceSelected;
        const annualAmountMatch =
            oldRecurringAnnualPrice?.Amount ===
            parseFloat(values?.recurringAnnualPrice) * 100;

        const monthlyNotActiveAndMatch =
            !values?.recurringMonthlyPriceSelected && monthlyActiveMatch;
        const annualNotActiveAndMatch =
            !values?.recurringAnnualPriceSelected && annualActiveMatch;

        return (
            (monthlyNotActiveAndMatch && annualNotActiveAndMatch) ||
            (monthlyActiveMatch &&
                monthlyAmountMatch &&
                annualActiveMatch &&
                annualAmountMatch)
        );
    }, [values, oldRecurringAnnualPrice, oldRecurringMonthlyPrice]);

    const updatePrices = useCallback(async () => {
        // Destructure form values
        const {
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            recurringApplySubscriptionUpdatesNewOnly
        } = values;

        // Build the array for the PUT request
        const pricesArray = buildPricesArray({
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            oneTimePrice: null,
            oldProduct,
            oldRecurringMonthlyPrice,
            oldRecurringAnnualPrice,
            oldOneTimePrice: null,
            name: "Catalog Pass",
            selectedPassType: "recurring"
        });

        try {
            await putProductPrices([
                accounts[0]?.Id,
                oldProduct?.Id,
                {
                    Prices: pricesArray,
                    UpdateExistingCustomersToPrice:
                        !recurringApplySubscriptionUpdatesNewOnly
                }
            ]);

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: t(
                        "gated-content-modal:messages:pricing-update-success-catalog"
                    )
                })
            );
        } catch (e) {
            rollbar.error("Error updating catalog pass", e);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t(
                        "gated-content-modal:errors:pricing-update-error"
                    )
                })
            );
        } finally {
            getEntitlementAndPrices();
        }
    }, [
        dispatch,
        t,
        oldProduct,
        oldRecurringMonthlyPrice,
        oldRecurringAnnualPrice,
        accounts,
        getEntitlementAndPrices,
        putProductPrices,
        values
    ]);

    const handleSubmit = useCallback(async () => {
        const {
            name,
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice
        } = values;

        if (Object.keys(priceErrors).length > 0) {
            return;
        }

        if (createOrUpdate === PricingModalTypes.Create) {
            const getPrices = (
                productId: string
            ): CreatorProductPricesBindingModel[] => {
                const prices = [];

                if (recurringMonthlyPriceSelected) {
                    prices.push(
                        buildPriceCreateObj(
                            name,
                            productId,
                            recurringMonthlyPrice,
                            true,
                            "month"
                        )
                    );
                }

                if (recurringAnnualPriceSelected) {
                    prices.push(
                        buildPriceCreateObj(
                            name,
                            productId,
                            recurringAnnualPrice,
                            true,
                            "year"
                        )
                    );
                }

                return prices;
            };

            try {
                const res = await postProduct([
                    accounts[0]?.Id,
                    {
                        Products: [
                            {
                                Id: uuidv4(),
                                Name: "Catalog Pass",
                                StripeAccountId: accounts[0]?.Id,
                                IsActive: true
                            }
                        ]
                    }
                ]);

                await postProductPrices([
                    accounts?.[0]?.Id,
                    res?.Products?.[0]?.Id,
                    {
                        Prices: getPrices(res?.Products?.[0]?.Id)
                    }
                ]);

                await postEntitlement([
                    {
                        ProductEntitlements: [
                            {
                                ProductId: res?.Products[0]?.Id,
                                Discriminator:
                                    CreatorProductEntitlementsBindingModelDiscriminator._3,
                                CatalogId: catalog?.Details?.Id
                            }
                        ]
                    }
                ]);

                dispatch(postEvents({ "created-pass": true }));
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t(
                            "gated-content-modal:messages:pricing-create-success"
                        )
                    })
                );
            } catch (e) {
                rollbar.error("Error creating catalog pass", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t(
                            "gated-content-modal:errors:pricing-create-error"
                        )
                    })
                );
            }

            await updatePrices();
        } else if (createOrUpdate === PricingModalTypes.Update) {
            // If there are no changes to the recurring prices, show a success message and return
            if (recurringPassHasNoChanges) {
                dispatch(
                    addNotification({
                        type: NotificationType.Info,
                        message: t(
                            "gated-content-modal:messages:pricing-update-success-catalog"
                        )
                    })
                );
                getEntitlementAndPrices();
                return;
            }

            // If the user has active subscriptions and is trying to cancel both recurring prices, show a warning modal
            const subscriberCount = oldProduct?.ActiveSubscriptions;
            if (
                Number.isInteger(subscriberCount) &&
                subscriberCount > 0 &&
                !recurringMonthlyPriceSelected &&
                !recurringAnnualPriceSelected
            ) {
                dispatch(
                    setActiveModal({
                        id: Modals.AttentionModal,
                        type: Modals.AttentionModal,
                        component: (
                            <AttentionModal
                                isOpen={true}
                                setIsOpen={() => dispatch(closeCurrentModal())}
                                handleCancel={() =>
                                    dispatch(closeCurrentModal())
                                }
                                hasContinueButton={true}
                                handleContinue={async () => {
                                    postEntitlement([
                                        {
                                            ProductEntitlements: [
                                                {
                                                    ProductId: oldProduct.Id,
                                                    Discriminator:
                                                        CreatorProductEntitlementsBindingModelDiscriminator._3,
                                                    CatalogId:
                                                        catalog?.Details?.Id
                                                }
                                            ]
                                        }
                                    ]);

                                    updatePrices();
                                    await removeActiveSubscriptions();

                                    dispatch(closeCurrentModal());
                                }}
                                continueText={t("buttons:continue")}
                            >
                                <p>{t("catalog-cancellation-warning")}</p>
                            </AttentionModal>
                        )
                    })
                );
            } else {
                await postEntitlement([
                    {
                        ProductEntitlements: [
                            {
                                ProductId: oldProduct.Id,
                                Discriminator:
                                    CreatorProductEntitlementsBindingModelDiscriminator._3,
                                CatalogId: catalog?.Details?.Id
                            }
                        ]
                    }
                ]);

                await updatePrices();
            }
        }
    }, [
        accounts,
        catalog?.Details?.Id,
        oldProduct,
        createOrUpdate,
        priceErrors,
        postProduct,
        getEntitlementAndPrices,
        postProductPrices,
        postEntitlement,
        dispatch,
        values,
        t,
        recurringPassHasNoChanges,
        removeActiveSubscriptions,
        updatePrices
    ]);

    const validateFields = useCallback(
        ({
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringAnnualPrice,
            recurringMonthlyPrice
        }: GatedContentPassFormProps) => {
            let errors: StringDictionary = {};
            const validatePrice = (id: string, price: string) => {
                const baseError = t(
                    "gated-content-modal:errors:price-min-error"
                );
                if (!price) {
                    errors[id] = t("gated-content-modal:errors:price-error");
                }

                const minPrices = {
                    recurringAnnualPrice: import.meta.env
                        .VITE_MINIMUM_ANNUAL_PRICE,
                    recurringMonthlyPrice: import.meta.env
                        .VITE_MINIMUM_MONTHLY_PRICE,
                    oneTimePrice: import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE
                };

                if (parseFloat(price) < parseFloat(minPrices[id])) {
                    errors[id] = `${baseError} $${minPrices[id]}`;
                }
            };

            if (recurringMonthlyPriceSelected) {
                validatePrice("recurringMonthlyPrice", recurringMonthlyPrice);
            }
            if (recurringAnnualPriceSelected) {
                validatePrice("recurringAnnualPrice", recurringAnnualPrice);
            }

            setPriceErrors(errors);
            return errors;
        },
        [t]
    );

    const handlePriceChange = useCallback(
        (key: string, val: any) => {
            setValues({ ...values, [key]: val });
            validateFields({ ...values, [key]: val });
        },
        [values, validateFields, setValues]
    );

    useBeforeUnload(!recurringPassHasNoChanges, null, true);

    return (
        <div>
            {!loading && !catalogLoading && (
                <div>
                    <StripeConnectCard
                        details={details}
                        gatedContentStatus={gatedContentStatus}
                        variant="Catalog"
                    />
                    {isSetup && values && (
                        <RecurringPassField
                            errors={priceErrors}
                            type={createOrUpdate}
                            values={values}
                            variant="catalog"
                            onChange={handlePriceChange}
                            onSubmit={() => handleSubmit()}
                            submitDisabled={recurringPassHasNoChanges}
                            creatorProductId={
                                entitlementAndPrices?.ProductEntitlements?.[0]
                                    ?.ProductId
                            }
                        />
                    )}
                </div>
            )}
        </div>
    );
};
