import styles from "./index.module.scss";
import TabletPreview from "assets/device-previews/tablet.png";
import MobilePreview from "assets/device-previews/mobile.png";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
    DeviceType,
    PlayerDetailsResponseAspectRatio,
    PlayerDetailsResponseEmbeddedDisplay
} from "@switcherstudio/switcher-api-client";
import { useEmbedCode } from "hooks/useEmbedCode";
import { useParams } from "hooks/useParams";

export interface DevicePreviewPageProps {
    catalogId?: string;
    videoPlayerId?: string;
    device?: string;
    embeddedDisplay?: string;
    aspectRatio?: string;
}

const dimensions = {
    "iPhone": {
        inner: { width: 642, height: 1143 },
        outer: { width: 766, height: 1460 },
        offset: { x: 0, y: 15 }
    },
    "iPad": {
        inner: { width: 820, height: 1092.55 },
        outer: { width: 912.15, height: 1385.409523809523 },
        offset: { x: -1, y: 0 }
    }
};

export const DevicePreviewPage: React.FC = (props) => {
    const {
        device: _device,
        catalogId,
        videoPlayerId,
        embeddedDisplay: _embeddedDisplay = undefined,
        aspectRatio: _aspectRatio = undefined
    } = useParams<DevicePreviewPageProps>(props);

    const variant = useMemo<DeviceType>(() => {
        if (_device == "iPad") return DeviceType.IPad;
        if (_device == "iPhone") return DeviceType.IPhone;
    }, [_device]);


    const embeddedDisplay =
        useMemo<PlayerDetailsResponseEmbeddedDisplay>(() => {
            return _embeddedDisplay
                ? (parseInt(
                      _embeddedDisplay
                  ) as PlayerDetailsResponseEmbeddedDisplay)
                : undefined;
        }, [_embeddedDisplay]);

    const aspectRatio = useMemo<PlayerDetailsResponseAspectRatio>(() => {
        return _aspectRatio as PlayerDetailsResponseAspectRatio | undefined;
    }, [_aspectRatio]);

    const embedRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    /** The device preview is loading a catalog embed */
    const isCatalog = useMemo<boolean>(() => !!catalogId, [catalogId]);

    const { embedCode } = useEmbedCode(
        isCatalog ? "catalog" : "collection",
        isCatalog ? catalogId : videoPlayerId
    );

    /** True if loaded iframe is a player, not including the catalog view or a collection in grid or carousel view  */
    const isPlayer = useMemo<boolean>(
        () =>
            !isCatalog &&
            embeddedDisplay === PlayerDetailsResponseEmbeddedDisplay._0,
        [embeddedDisplay, isCatalog]
    );

    const resizeDialog = useCallback(
        (
            { skipTransform }: { skipTransform?: boolean } = {
                skipTransform: false
            }
        ) => {
            const div = embedRef.current
                .getElementsByClassName("dff402f7-5be0-4890-b831-95c5b63ddb42")
                .item(0) as HTMLDivElement;

            const dialog = div.children.item(
                isPlayer ? 0 : 1
            ) as HTMLDialogElement;

            if (!dialog || !imageRef.current) return;

            const { width } = imageRef.current.getBoundingClientRect();

            const { inner, outer, offset } = dimensions[variant];

            dialog.style.margin = "auto";

            dialog.style.width = `${inner.width}px`;

            if (
                skipTransform &&
                isPlayer &&
                aspectRatio === PlayerDetailsResponseAspectRatio.SixteenByNine
            ) {
                const calculatedHeight = inner.width * (9 / 16);

                dialog.style.height = `${calculatedHeight}px`;
                dialog.style.minHeight = `${calculatedHeight}px`;
            } else {
                dialog.style.height = `${inner.height}px`;
                dialog.style.minHeight = `${inner.height}px`;
            }

            if (!skipTransform) {
                dialog.style.transform = `scale(${
                    width / outer.width
                }) translate(${0}px, ${offset.y}px)`;
            }
        },
        [aspectRatio, isPlayer, variant]
    );

    const resetDialog = useCallback(() => {
        const div = embedRef.current
            .getElementsByClassName("dff402f7-5be0-4890-b831-95c5b63ddb42")
            .item(0) as HTMLDivElement;

        const dialog = div.children.item(0) as HTMLDialogElement;
        const { inner } = dimensions[variant];

        if (!dialog || !imageRef.current) return;

        if (
            isPlayer &&
            aspectRatio === PlayerDetailsResponseAspectRatio.SixteenByNine
        ) {
            const calculatedHeight = inner.width * (9 / 16);

            dialog.style.height = `${calculatedHeight}px`;
            dialog.style.minHeight = `${calculatedHeight}px`;
        } else {
            dialog.style.height = `${inner.height}px`;
            dialog.style.minHeight = `${inner.height}px`;
        }

        dialog.style.transform = `scale(1)`;
    }, [aspectRatio, isPlayer, variant]);

    const handleResize = useCallback(() => {
        if (!imageRef.current) return;
        const { width } = imageRef.current.getBoundingClientRect();

        const { inner, outer, offset } = dimensions[variant];

        embedRef.current.style.width = `${inner.width}px`;

        if (
            isPlayer &&
            aspectRatio === PlayerDetailsResponseAspectRatio.SixteenByNine
        ) {
            const calculatedHeight = inner.width * (9 / 16);

            embedRef.current.style.height = `${calculatedHeight}px`;
            embedRef.current.style.minHeight = `${calculatedHeight}px`;

            wrapperRef.current.style.transform = `translate(0px, ${-(
                inner.height / 2 -
                calculatedHeight +
                offset.y
            )}px)`;
        } else {
            embedRef.current.style.height = `${inner.height}px`;
            embedRef.current.style.minHeight = `${inner.height}px`;
        }
        wrapperRef.current.style.transform += `scale(${width / outer.width})`;

        resizeDialog({ skipTransform: isPlayer });
    }, [aspectRatio, isPlayer, resizeDialog, variant]);

    useEffect(() => {
        imageRef.current.onload = () => handleResize();

        if (isCatalog) {
            window.onresize = handleResize;
            window.addEventListener("switcherPlayerAppLoaded", () =>
                resizeDialog()
            );
        } else {
            window.addEventListener("switcherPlayerAppLoaded", () =>
                resizeDialog({ skipTransform: isPlayer })
            );

            window.addEventListener("switcherPlayerDialogOpened", () =>
                resizeDialog()
            );

            window.addEventListener("switcherPlayerDialogClosed", () =>
                resetDialog()
            );
        }
    }, [
        resizeDialog,
        handleResize,
        isCatalog,
        resetDialog,
        embeddedDisplay,
        isPlayer
    ]);

    useEffect(() => {
        if (!embedCode && !embedRef.current) return;
        const slotHtml = document
            .createRange()
            .createContextualFragment(embedCode);
        embedRef.current.innerHTML = "";
        embedRef.current.appendChild(slotHtml);
    }, [embedCode]);

    return (
        <div className={styles["background"]}>
            <img
                className={styles["device"]}
                ref={imageRef}
                alt="Device preview"
                title="Device preview"
                src={
                    variant === DeviceType.IPhone
                        ? MobilePreview
                        : TabletPreview
                }
            />
            <div className={styles["embed-wrapper"]} ref={wrapperRef}>
                <div
                    className={
                        styles[isPlayer ? "embedded-player" : "embedded"]
                    }
                    ref={embedRef}
                />
            </div>
        </div>
    );
};
