import React, { useState, useEffect } from "react";
import { useNavigate } from "hooks/useNavigate";
import ChevronLeft from "assets/icons/chevron-left.svg?react";
import { client } from "api/client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { SilverSunnStripeCreditCard } from "@switcherstudio/switcher-api-client";
import { PrimaryPaymentMethodPageProps } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";
import { SubscriptionFormWrapper } from "../../SubscriptionFormWrapper";
import { AppDispatch } from "store/store";
import commonStyles from "../../SubscriptionCommonStyles.module.scss";
import { useParams } from "hooks/useParams";

export const PrimaryPaymentMethodPage: React.FC = (props) => {
    const { paymentMethodId } = useParams<PrimaryPaymentMethodPageProps>(props);

    const { t } = useTranslation();
    const isMounted = useIsMountedRef();
    const { navigate } = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const [card, setCard] = useState<SilverSunnStripeCreditCard>();
    const [error, setError] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        async function run() {
            const customer = await client.stripe_GetCustomer();

            if (isMounted.current) {
                const customerCard = customer?.StripeCreditCards?.find(
                    (c) => c.SilverSunnStripeCreditCardId === paymentMethodId
                );

                if (customerCard && customer.SilverSunnStripeCustomerId) {
                    setCard(customerCard);
                } else {
                    setError(t("errors:something-wrong"));
                }
            }
        }

        run();
    }, [isMounted, paymentMethodId, t]);

    const back = () => {
        navigate("/subscription");
    };

    const setAsPrimary = async () => {
        setError("");
        setIsSubmitting(true);
        try {
            await client.userPaymentMethods_SetPrimaryPaymentMethod(
                userInfo.UserId,
                paymentMethodId
            );
            navigate("/subscription");
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: t("messages:primary-payment-updated")
                })
            );
        } catch (e) {
            rollbar.error("Error setting primary payment method", e);
            setError(t("errors:primary-card-error"));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <p>
                        <button
                            type="button"
                            className={`btn btn-outline-primary ${commonStyles["header-btn"]}`}
                            onClick={back}
                        >
                            <ChevronLeft />{" "}
                            {t("cancel-subscription:subscriptions")}
                        </button>
                    </p>

                    {card && (
                        <SubscriptionFormWrapper>
                            <h3>{t("payment-method-page:card-details")}</h3>
                            <p>
                                {t("payment-method-page:card-number")}: ****{" "}
                                {card.LastFour}
                            </p>
                            <p>
                                {t("subscription:expiration")}:{" "}
                                {card.ExpirationMonth} / {card.ExpirationYear}
                            </p>
                            <p className="lead">
                                {t("payment-method-page:make-primary")}
                            </p>
                            {error && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            )}
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={setAsPrimary}
                                disabled={isSubmitting}
                            >
                                {t("buttons:make-primary")}
                            </button>
                        </SubscriptionFormWrapper>
                    )}
                </div>
            </div>
        </>
    );
};
