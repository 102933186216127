import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { RootState } from "store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { ModalBase } from "../ModalBase";
import { useTranslation } from "react-i18next";
import { Wizard } from "components/wizards/Wizard";
import { SelectPlanStep } from "./steps/SelectPlanStep";
import { CheckoutStep } from "./steps/CheckoutStep";
import {
    SilverSunnStripeCreditCard,
    SilverSunnStripeCustomer,
    StripePrice,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { exists } from "helpers/booleans";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import { useRunOnce } from "hooks/useRunOnce";
import { useSettableMemo } from "hooks/useSettableMemo";
import { useCheckout } from "hooks/useCheckout";

export enum ManagePlanModalSteps {
    SelectPlan = "select-plan",
    Checkout = "checkout"
}

export interface ManagePlanModalProps {
    prices: StripePrice[];
    preselectedPrice?: StripePrice | StripePriceAnonymous;
    couponCode?: string;
    stripePricesLoading: boolean;
    customer: SilverSunnStripeCustomer;
    onCheckoutSuccess: () => void;
    onCheckoutError: (message: string) => void;
}

export const ManagePlanModal = ({
    prices,
    preselectedPrice,
    couponCode,
    stripePricesLoading,
    customer,
    onCheckoutSuccess,
    onCheckoutError
}: ManagePlanModalProps) => {
    const { userInfo } = useSelector((s: RootState) => s.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedPlan, setSelectedPlan] = useState<
        StripePrice | StripePriceAnonymous | undefined
    >();
    const [paymentMethodReady, setPaymentMethodReady] =
        useState<boolean>(false);

    const activeStep = useMemo<ManagePlanModalSteps>(
        () =>
            !selectedPlan
                ? ManagePlanModalSteps.SelectPlan
                : ManagePlanModalSteps.Checkout,
        [selectedPlan]
    );

    const {
        claimedCoupon,
        addClaimedCoupon,
        removeClaimedCoupon,
        loading: loadingCoupon,
        error: couponError
    } = useGetLastClaimedCoupon();

    useEffect(() => {
        if (preselectedPrice) {
            /** if the url contains a planId, this takes the user directly to the checkout step */
            setSelectedPlan(preselectedPrice);
            /** enables the Subscribe button */
            setLocalSelectedPlan(preselectedPrice);
        }
    }, [preselectedPrice]);

    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useSettableMemo<SilverSunnStripeCreditCard>(
            () => customer?.StripeCreditCards.find((cc) => cc.Default),
            [customer]
        );

    useRunOnce(() => {
        setPaymentMethodReady(true);
    }, exists(selectedPaymentMethod));

    const [addingNewPaymentMethod, setAddingNewPaymentMethod] =
        useState<boolean>(false);

    const { data: proration, dispatchApiRequest: getProrationAmounts } =
        useSwitcherClient((client) => client.stripe_GetProration, {
            requestImmediately: false
        });

    const { data: invoice, dispatchApiRequest: getInvoice } = useSwitcherClient(
        (client) => client.stripeInvoices_PostInvoice,
        {
            requestImmediately: false
        }
    );

    const { reset } = useRunOnce(
        () =>
            selectedPlan.IsRecurring
                ? getProrationAmounts([
                      selectedPlan?.Id,
                      1,
                      customer?.StripeSubscriptions?.[0]
                          ?.SilverSunnStripeSubscriptionId,
                      claimedCoupon?.ResellerInventoryItem.Id
                  ])
                : getInvoice([
                      userInfo.UserId,
                      {
                          InvoiceItems: [{ Price: selectedPlan?.Id }],
                          ResellerInventoryItemId:
                              claimedCoupon?.ResellerInventoryItem.Id
                      }
                  ]),
        exists(customer) && exists(selectedPlan)
    );

    /** if the currentPlan !isPublic, it won't be selected in the modal */
    const currentPlan = useMemo<StripePrice>(
        () => prices?.filter((price) => price.Id === userInfo?.PlanId)?.[0],
        [prices, userInfo?.PlanId]
    );

    const [localSelectedPlan, setLocalSelectedPlan] = useState<
        StripePrice | StripePriceAnonymous | undefined
    >(currentPlan);

    const close = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.None,
                type: Modals.None
            })
        );
    }, [dispatch]);

    const successDisabled = useMemo<boolean>(() => {
        if (loadingCoupon) return true;
        switch (activeStep) {
            case ManagePlanModalSteps.SelectPlan:
                // There is no selected plan, then is disabled
                if (localSelectedPlan === undefined) return true;
                // Otherwise, if the plan is cancelled or the user is trialing
                else if (
                    userInfo?.CancelAtPeriodEnd ||
                    userInfo.Status === "trialing" ||
                    couponCode
                ) {
                    // They can select whichever plan they want
                    return false;
                } else {
                    // Otherwise they can't select the plan they are currently on
                    return localSelectedPlan?.Id === currentPlan?.Id;
                }
            case ManagePlanModalSteps.Checkout:
                return !selectedPaymentMethod && !paymentMethodReady;
        }
    }, [
        activeStep,
        couponCode,
        currentPlan?.Id,
        loadingCoupon,
        localSelectedPlan,
        paymentMethodReady,
        selectedPaymentMethod,
        userInfo?.CancelAtPeriodEnd,
        userInfo.Status
    ]);

    /** Call handleSubmit function from child component */
    const handleSelectPlanSubmit = useCallback(
        (onHandleSubmit: () => void) => {
            /** !stripePricesLoading stops this from running and throwing errors on initial render */
            if (!stripePricesLoading) {
                onHandleSubmit();
            }
        },
        [stripePricesLoading]
    );

    const { checkout, isSubmitting } = useCheckout({
        proration,
        invoice,
        selectedPaymentMethodId: addingNewPaymentMethod
            ? "new-payment-method"
            : selectedPaymentMethod?.SilverSunnStripeCreditCardId,
        claimedCoupon,
        plan: selectedPlan,
        customer,
        onSuccess: () => {
            close();
            onCheckoutSuccess();
        },
        onError: onCheckoutError
    });

    return (
        <ModalBase
            isOpen
            header={t("subscription:manage-plan")}
            onSuccess={() =>
                activeStep === "select-plan"
                    ? setSelectedPlan(localSelectedPlan)
                    : checkout()
            }
            successDisabled={successDisabled}
            successButton={
                activeStep === "select-plan"
                    ? t("buttons:update")
                    : t("buttons:subscribe")
            }
            shouldNotCloseOnSuccess={true}
            onDismiss={close}
            dismissButton={t("buttons:cancel")}
            loading={isSubmitting}
        >
            <div className={styles["container"]}>
                <Wizard<ManagePlanModalSteps>
                    activeStep={activeStep}
                    steps={[
                        {
                            id: ManagePlanModalSteps.SelectPlan,
                            component: (
                                <SelectPlanStep
                                    loading={stripePricesLoading}
                                    prices={prices}
                                    currentPlan={userInfo?.PlanId}
                                    selectPlan={(plan) => {
                                        setLocalSelectedPlan(plan);
                                    }}
                                    onHandleSubmit={handleSelectPlanSubmit}
                                />
                            )
                        },
                        {
                            id: ManagePlanModalSteps.Checkout,
                            component: (
                                <CheckoutStep
                                    selectedPlan={selectedPlan}
                                    unselectPlan={() => {
                                        reset();
                                        setSelectedPlan(undefined);
                                    }}
                                    customer={customer}
                                    proration={proration}
                                    invoice={invoice}
                                    claimedCoupon={claimedCoupon}
                                    loadingCoupon={loadingCoupon}
                                    addingNewPaymentMethod={
                                        addingNewPaymentMethod
                                    }
                                    setAddingNewPaymentMethod={
                                        setAddingNewPaymentMethod
                                    }
                                    selectedPaymentMethod={
                                        selectedPaymentMethod
                                    }
                                    setSelectedPaymentMethod={
                                        setSelectedPaymentMethod
                                    }
                                    couponError={couponError}
                                    addClaimedCoupon={addClaimedCoupon}
                                    removeClaimedCoupon={removeClaimedCoupon}
                                    onPaymentMethodChange={(id, event) => {
                                        if (id !== "new-payment-method") {
                                            setPaymentMethodReady(true);
                                        } else {
                                            setPaymentMethodReady(
                                                event.complete
                                            );
                                        }
                                    }}
                                />
                            )
                        }
                    ]}
                />
            </div>
        </ModalBase>
    );
};
