import { DragDrop } from "@uppy/react";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../index.module.scss";
import { Trans } from "react-i18next";
import { Link } from "react-navi";
import { AttentionModal } from "components/modal/AttentionModal";
import { useGetCloudUsageForUploadSessions } from "../../hooks/useGetCloudUsageForUploadSessions";
import { useHandleUploadModalAccess } from "../../hooks/useHandleUploadModalAccess";
import Uppy from "@uppy/core";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useResetUploadSession } from "components/video-upload/hooks/useResetUploadSession";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { ProgressBar } from "components/widgets/progress-bar/ProgressBar";
import { Modals } from "store/modal/types";
import { ContactSalesModal } from "components/modal/ContactSalesModal";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useIsMobile } from "hooks/useIsMobile";
import { useNavigate } from "hooks/useNavigate";
import { useHasPlanRole } from "hooks/useHasPlanRole";
export interface DragAndDropProps {
    sessionId: string;
    initialUppyInstance?: Uppy;
    isVideoLibrary?: boolean;
    showVideoStorageBar?: boolean;
    refreshKey?: number;
}

export const DragAndDrop = ({
    sessionId,
    initialUppyInstance,
    isVideoLibrary = false,
    showVideoStorageBar = false,
    refreshKey
}: DragAndDropProps) => {
    const isStudioUser = useHasPlanRole("Studio");
    const { navigate } = useNavigate();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();
    const { resetUploadSession } = useResetUploadSession();
    const link = useMemo(() => "/video-library", []);
    const {
        libraryFull,
        addedVideosExceedCloudUsage,
        cloudTotal,
        cloudMax,
        cloudUsageLoading,
        updateUsage
    } = useGetCloudUsageForUploadSessions();
    const [attentionModalIsOpen, setAttentionModalIsOpen] = useState<boolean>(
        addedVideosExceedCloudUsage
    );

    const [localVideosExceedCloudUsage, setLocalVideosExceedCloudUsage] =
        useState<boolean>(addedVideosExceedCloudUsage);

    const hasCatalogClaim = useClaimCheck("catalog");

    const dispatch = useDispatch<AppDispatch>();
    // resets the upload modal once files are added if over limits, opens modal if under limit and not already open
    useHandleUploadModalAccess({ sessionId, showNotifications: false });

    // update cloud usage when we are done uploading
    useEffect(() => {
        updateUsage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshKey]);

    // open modal when added videos first exceed limit
    useEffect(() => {
        if (addedVideosExceedCloudUsage) {
            setAttentionModalIsOpen(true);
            setLocalVideosExceedCloudUsage(true);
        }
    }, [addedVideosExceedCloudUsage]);

    const styleVariant = useMemo<
        | undefined
        | "cloud-assets"
        | "thick"
        | "video-storage-medium"
        | "video-storage-max"
    >(() => {
        if (cloudTotal >= cloudMax) {
            return "video-storage-max";
        }
        if (cloudTotal / cloudMax >= 0.8) {
            return "video-storage-medium";
        }
        return "thick";
    }, [cloudTotal, cloudMax]);

    const getVideoLimitReachedText = useCallback(() => {
        if (libraryFull) {
            if (isVideoLibrary) {
                if (isStudioUser) {
                    return t("video-player-page:video-limit-reached-studio");
                } else {
                    return t("video-player-page:video-limit-reached-vl");
                }
            } else {
                return t("video-player-page:video-limit-reached");
            }
        }
        return "";
    }, [libraryFull, isVideoLibrary, isStudioUser, t]);

    const getVideoLimitApproachingText = useCallback(() => {
        return isStudioUser
            ? t("video-player-page:video-limit-approaching-studio")
            : t("video-player-page:video-limit-approaching-vl");
    }, [isStudioUser, t]);

    const i18nKey = useMemo(() => {
        if (libraryFull) {
            return getVideoLimitReachedText();
        }

        if (localVideosExceedCloudUsage) {
            return t("video-player-page:attention-action");
        }

        if (cloudTotal / cloudMax >= 0.8) {
            return getVideoLimitApproachingText();
        }

        return "";
    }, [
        libraryFull,
        localVideosExceedCloudUsage,
        cloudTotal,
        cloudMax,
        getVideoLimitReachedText,
        t,
        getVideoLimitApproachingText
    ]);

    const translationsWithLinks = (
        <Trans
            i18nKey={i18nKey}
            components={{
                link1: (
                    <Link
                        href={link}
                        onClick={() => {
                            resetUploadSession({ sessionId });
                            dispatch(closeModal);
                            dispatch(closeCurrentModal());
                        }}
                        title="Video Library"
                        className={styles["link"]}
                    />
                ),
                link2: (
                    <span
                        onClick={() => {
                            setAttentionModalIsOpen(false);
                            dispatch(
                                setActiveModal({
                                    id: Modals.ContactSalesModal,
                                    type: Modals.ContactSalesModal,
                                    component: <ContactSalesModal />
                                })
                            );
                        }}
                        className={styles["link"]}
                    />
                ),
                link3: (
                    <span
                        onClick={() => {
                            navigate(`/billing`);
                        }}
                        className={styles["link"]}
                    />
                )
            }}
        />
    );

    const closeModal = useCallback(() => {
        setLocalVideosExceedCloudUsage(false);
        setAttentionModalIsOpen(false);
    }, []);

    // Main text to show in the drag and drop component
    const dragAndDropLocaleStrings = useMemo(() => {
        // `%{browse}` is replaced with a link that opens the system file selection dialog.
        const dropHereOr = `%{browse} ${
            !isMobile ? t("video-upload:drag-drop") : ""
        }`;

        // Determine the label for the link that opens the system file selection dialog
        let browse: string;
        if (isVideoLibrary) {
            browse = isMobile
                ? t("video-upload:browse-link-library-mobile")
                : t("video-upload:browse-link-library");
        } else if (hasCatalogClaim) {
            browse = isMobile
                ? t("video-upload:browse-link-collection-mobile")
                : t("video-upload:browse-link-collection");
        } else {
            browse = t("video-upload:browse-link-playlist");
        }

        return { strings: { dropHereOr, browse } };
    }, [isVideoLibrary, isMobile, hasCatalogClaim, t]);

    return (
        <div className={styles["drag-drop-and-warning-container"]}>
            {!libraryFull && initialUppyInstance && (
                <DragDrop
                    width="100%"
                    height="100%"
                    uppy={initialUppyInstance}
                    locale={dragAndDropLocaleStrings}
                    note={t("video-upload:restrictions")}
                    className={styles["compact-upload"]}
                />
            )}
            {(isVideoLibrary || i18nKey !== "" || !cloudUsageLoading) && (
                <div className={styles["limit-warning"]}>
                    {i18nKey !== "" && <div>{translationsWithLinks}</div>}
                    {showVideoStorageBar && (
                        <ProgressBar
                            currentValue={cloudTotal}
                            maxValue={cloudMax}
                            variant={styleVariant}
                        />
                    )}
                </div>
            )}

            <AttentionModal
                isOpen={attentionModalIsOpen}
                setIsOpen={setAttentionModalIsOpen}
                handleCancel={closeModal}
                hasContinueButton={false}
            >
                {i18nKey !== "" && (
                    <div className={styles["limit-warning"]}>
                        {translationsWithLinks}
                    </div>
                )}
            </AttentionModal>
        </div>
    );
};
