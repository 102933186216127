import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import { PaymentMethodCardV2 } from "./PaymentMethodCardV2";
import rollbar from "helpers/rollbar";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";
import styles from "./index.module.scss";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";
import { AttentionModal } from "../AttentionModal";
import { ComponentItem } from "components/generic-multiselect/types";
import { PaymentMethodInput } from "components/inputs/text-input/PaymentMethodInput";
import { Button } from "components/buttons/Button";
import { useStripeHandlers } from "hooks/useStripeHandlers";
import { GenericMultiSelect } from "components/generic-multiselect";
import { ModalBase } from "../ModalBase";
import {
    SilverSunnStripeCreditCard,
    SilverSunnStripeCustomer
} from "@switcherstudio/switcher-api-client";
import { useSettableMemo } from "hooks/useSettableMemo";
import { createUrl } from "helpers/url";

interface ManagePaymentDetailsProps {
    customer: SilverSunnStripeCustomer;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onUpdate: () => void;
}

export const ManagePaymentDetails = ({
    customer,
    isOpen,
    setIsOpen,
    onUpdate
}: ManagePaymentDetailsProps) => {
    const { userInfo } = useSelector((state: RootState) => state.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [attentionModalIsOpen, setAttentionModalIsOpen] =
        useState<boolean>(false);
    const [cardToRemove, setCardToRemove] = useState<string>(null);

    const [addingNewPaymentMethod, setAddingNewPaymentMethod] =
        useState<boolean>(false);

    const { stripe, elements } = useStripeHandlers();

    const [error, setError] = useState<string>("");

    const primaryCard = useMemo(
        () => customer?.StripeCreditCards?.find((card) => card.Default),
        [customer]
    );

    //this memo stabilizes the useEffect in the useGenericMultiSelect hook
    const previouslySelectedIds = useMemo(
        () => [primaryCard?.SilverSunnStripeCreditCardId],
        [primaryCard?.SilverSunnStripeCreditCardId]
    );

    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useSettableMemo<SilverSunnStripeCreditCard>(
            () => primaryCard,
            [customer]
        );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [paymentMethodReady, setPaymentMethodReady] =
        useState<boolean>(false);

    const submitDisabled = useMemo<boolean>(
        () =>
            (selectedPaymentMethod === null && !paymentMethodReady) ||
            isSubmitting,
        [isSubmitting, paymentMethodReady, selectedPaymentMethod]
    );

    //Resets the modal to default state when it is closed.
    useEffect(() => {
        if (!isOpen) {
            setIsSubmitting(false);
            setAddingNewPaymentMethod(false);
            setError("");
            setPaymentMethodReady(false);
            setSelectedPaymentMethod(primaryCard);
        }
    }, [isOpen, primaryCard, setSelectedPaymentMethod]);

    const onPaymentElementChange = useCallback(
        async (e: StripePaymentElementChangeEvent) => {
            if (e.complete) {
                setPaymentMethodReady(true);
            } else {
                setPaymentMethodReady(false);
            }
        },
        []
    );

    const { dispatchApiRequest: requestSetPrimaryCard } = useSwitcherClient(
        (client) => client.userPaymentMethods_SetPrimaryPaymentMethod,
        {
            transformResponseData: ({ originalArgs: [paymentMethodId] }) =>
                paymentMethodId,
            onSuccess: () => {
                setIsOpen(false);
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("messages:primary-payment-updated")
                    })
                );
                onUpdate();
            },
            onError: (e) => {
                rollbar.error("Error setting primary payment method", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:primary-card-error")
                    })
                );
            }
        }
    );

    const { dispatchApiRequest: removeCard, loading: removeCardLoading } =
        useSwitcherClient(
            (client) => client.userPaymentMethods_DeletePaymentMethod,
            {
                onSuccess: () => {
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: "messages:payment-remove-success"
                        })
                    );
                    onUpdate();
                },
                onError: (e) => {
                    rollbar.error("Error removing payment method", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:payment-remove-error"
                        })
                    );
                }
            }
        );

    const handleAttentionModalOpen = useCallback((cardId: string) => {
        setCardToRemove(cardId);
        setAttentionModalIsOpen(true);
    }, []);

    const handleAttentionModalClosed = useCallback(() => {
        setAttentionModalIsOpen(false);
        removeCard([userInfo.UserId, cardToRemove]);
    }, [cardToRemove, removeCard, userInfo.UserId]);

    const paymentMethods = useMemo<ComponentItem<any>[]>(() => {
        if (customer)
            return [
                ...[...(customer?.StripeCreditCards || [])]
                    .sort((a, b) =>
                        a.Default === b.Default ? 0 : a.Default ? -1 : 1
                    )
                    .map((card) => ({
                        id: card.SilverSunnStripeCreditCardId,
                        onClick: () => setAddingNewPaymentMethod(false),
                        component: (
                            <PaymentMethodCardV2
                                card={card}
                                removeCard={handleAttentionModalOpen}
                                removeCardLoading={removeCardLoading}
                            />
                        )
                    })),
                {
                    id: "new-payment-method",
                    hideSelector: !addingNewPaymentMethod,
                    onClick: () => setAddingNewPaymentMethod(true),
                    component: (
                        <>
                            {!addingNewPaymentMethod ? (
                                <div
                                    className={styles["add-new-payment-button"]}
                                >
                                    <Button type="link">
                                        {t("subscription-page:add-new-card")}
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    <PaymentMethodInput
                                        onChange={onPaymentElementChange}
                                    />

                                    {error && (
                                        <div className={styles["form-error"]}>
                                            {error}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )
                }
            ];
    }, [
        addingNewPaymentMethod,
        customer,
        error,
        handleAttentionModalOpen,
        onPaymentElementChange,
        removeCardLoading,
        t
    ]);

    const handleModalSubmit = useCallback(async () => {
        setIsSubmitting(true);
        if (selectedPaymentMethod === null) {
            // Handle adding new payment method
            try {
                const { error } = await stripe.confirmSetup({
                    elements,
                    redirect: "always",
                    confirmParams: {
                        return_url: createUrl(window.location.origin, {
                            pathname: "/subscription"
                        }).toString()
                    }
                });

                throw error;
            } catch (error) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:payment-add-error"
                    })
                );
            }
        } else {
            // Check if the selected card is different from the current primary card
            if (
                selectedPaymentMethod &&
                primaryCard?.SilverSunnStripeCreditCardId !==
                    selectedPaymentMethod.SilverSunnStripeCreditCardId
            ) {
                requestSetPrimaryCard([
                    userInfo.UserId,
                    selectedPaymentMethod.SilverSunnStripeCreditCardId
                ]);
            }
        }

        setIsOpen(false);
        onUpdate();
    }, [
        selectedPaymentMethod,
        primaryCard,
        setIsOpen,
        onUpdate,
        stripe,
        elements,
        userInfo.UserId,
        dispatch,
        requestSetPrimaryCard
    ]);

    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            header={t("subscription-page:manage-payment-details")}
            onSuccess={handleModalSubmit}
            onDismiss={isSubmitting ? () => {} : () => setIsOpen(false)}
            dismissButton={t("buttons:cancel")}
            successButton={t("buttons:update")}
            preventDismiss={attentionModalIsOpen}
            successDisabled={submitDisabled}
            shouldNotCloseOnSuccess
        >
            <div className={styles["container"]}>
                <GenericMultiSelect
                    isMultiple={false}
                    selectedIdSet={
                        new Set([
                            addingNewPaymentMethod
                                ? "new-payment-method"
                                : selectedPaymentMethod?.SilverSunnStripeCreditCardId
                        ])
                    }
                    previouslySelectedIds={previouslySelectedIds}
                    showNone={false}
                    items={paymentMethods}
                    handleSelect={(id) => {
                        if (id === "new-payment-method") {
                            setSelectedPaymentMethod(null);
                            setAddingNewPaymentMethod(true);
                        } else {
                            setAddingNewPaymentMethod(false);
                            setSelectedPaymentMethod(
                                customer.StripeCreditCards.find(
                                    (c) => c.SilverSunnStripeCreditCardId === id
                                )
                            );
                        }
                    }}
                />
            </div>
            <AttentionModal
                isOpen={attentionModalIsOpen}
                setIsOpen={setAttentionModalIsOpen}
                handleCancel={() => setAttentionModalIsOpen(false)}
                hasContinueButton={true}
                handleContinue={() => handleAttentionModalClosed()}
                continueText={t("buttons:delete")}
            >
                <>
                    <div className={styles["delete-confirmation"]}>
                        {t("subscription-page:delete-payment-confirmation")}
                    </div>
                </>
            </AttentionModal>
        </ModalBase>
    );
};
