import { StripeElement } from "components/stripe/StripeElement";
import { useParams } from "hooks/useParams";
import { OnboardingPage } from "./OnboardingPage";
import { parseBool } from "helpers/booleans";
import { loadStripe, SetupIntent } from "@stripe/stripe-js";
import { client } from "api/client";
import { useEffect, useRef, useState } from "react";

interface OnboardingWrapperProps {
    planId: string;
    dts: string;
    couponCode: string;
    resellerInventoryItemId: string;
    isTrialing: string;
    client_id: string;
    tag: string;
    returnScheme: string;
    setup_intent: string;
    setup_intent_client_secret: string;
    redirect_status: string;
}

export const OnboardingWrapper: React.FC = (props) => {
    const {
        planId,
        dts,
        couponCode,
        resellerInventoryItemId,
        isTrialing,
        client_id,
        tag,
        returnScheme,
        setup_intent,
        setup_intent_client_secret,
        redirect_status
    } = useParams<OnboardingWrapperProps>(props);

    const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
    const [registerBodyObject, setRegisterBodyObject] =
        useState<any>(undefined);
    const setupIntentComplete = useRef(false);
    useEffect(() => {
        const getSetupIntent = async () => {
            try {
                const stripe = await loadStripe(
                    import.meta.env.VITE_STRIPE_API_KEY
                );

                const setupIntent = setup_intent
                    ? (
                          await stripe.retrieveSetupIntent(
                              setup_intent_client_secret
                          )
                      ).setupIntent
                    : ((await client.stripeIntents_GetSetupIntent()) as SetupIntent);

                const registerBodyJson =
                    localStorage.getItem("onboarding-body");
                localStorage.removeItem("onboarding-body");

                const registerBodyObject = !!registerBodyJson
                    ? JSON.parse(registerBodyJson)
                    : undefined;

                setSetupIntent(setupIntent);
                setRegisterBodyObject(registerBodyObject);
                setupIntentComplete.current = true;
            } catch (error) {}
        };
        getSetupIntent();
    }, [setup_intent, setup_intent_client_secret]);

    return setupIntent ? (
        <StripeElement
            options={{
                clientSecret: setupIntent.client_secret
            }}
        >
            <OnboardingPage
                planId={planId}
                defaultToSubscribe={parseBool(dts)}
                isTrialing={parseBool(isTrialing) ?? true}
                couponCode={
                    couponCode ?? import.meta.env.VITE_DEFAULT_DTS_COUPON
                }
                resellerInventoryItemId={resellerInventoryItemId}
                clientId={client_id ?? "SilverSunnDashboard"}
                tag={tag}
                returnScheme={returnScheme ?? null}
                setupIntent={setupIntent}
                redirectStatus={redirect_status}
                registerBody={registerBodyObject}
            />
        </StripeElement>
    ) : null;
};
