import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { NewConfirmSubscriptionPage } from "./NewConfirmSubscriptionPage";
import { NewConfirmOneTimePage } from "./NewConfirmOneTimePage";
import { StripeElement } from "components/stripe/StripeElement";
import { client } from "api/client";
import { StripePrice } from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { useParams } from "hooks/useParams";

interface ConfirmationWrapperProps {
    priceId?: string;
    subscription?: string;
}

export const ConfirmationWrapper: React.FC = (props) => {
    const { priceId, subscription } =
        useParams<ConfirmationWrapperProps>(props);
    const { i18n } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const [plan, setPlan] = useState<StripePrice>();
    const [error, setError] = useState<boolean>(false);

    const isAppleSub = useMemo(() => {
        return (
            user.userInfo.ActiveProduct?.discriminator === "AppleProduct" &&
            user.userInfo.IsRecurring === true
        );
    }, [user.userInfo]);

    useRedirectIfDisallowed(() => !isAppleSub, "/subscription");
    useEffect(() => {
        async function run() {
            if (priceId) {
                try {
                    const userId = user.userInfo.UserId;
                    const plan = await client.userStripePrices_GetStripePrice(
                        userId,
                        priceId,
                        i18n.language.slice(0, 2)
                    );
                    setPlan(plan);
                } catch {
                    setError(true);
                }
            }
        }
        run();
    }, [user.userInfo.UserId, i18n, priceId]);

    if (priceId && plan) {
        if (plan.RecurringInterval) {
            return (
                <StripeElement>
                    <NewConfirmSubscriptionPage
                        plan={plan}
                        pricing={
                            plan.RecurringInterval === "month"
                                ? "monthly"
                                : "yearly"
                        }
                        subscriptionId={subscription}
                    />
                </StripeElement>
            );
        } else {
            return (
                <StripeElement>
                    <NewConfirmOneTimePage plan={plan} pricing={"one-time"} />
                </StripeElement>
            );
        }
    } else if (error) {
        return (
            <>
                <h1>Oops! Something went wrong.</h1>
            </>
        );
    } else {
        return <></>;
    }
};
