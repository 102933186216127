import React, { useMemo } from "react";
import { Route } from "navi";
import { NotFoundBoundary, View, useCurrentRoute } from "react-navi";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { NotificationContainer } from "components/notification/NotificationContainer";
import { useTranslation } from "react-i18next";
import i18n from "localization/i18n";
//import HelpIcon from "assets/icons/question-mark.svg?react"; //These imports are used for the Searchbar and Help Icon. which is disabled at the moment
//import { trackEvent } from "../../helpers/analyticsHelpers";
import { Data } from "navi-router";
import { PageContentHeader } from "components/page-content-header/PageContentHeader";
import { MinimizedModalTabManager } from "components/minimized-modal-tab/MinimizedModalTabManager";
import { useClaimCheck } from "hooks/useClaimCheck";

//import { SiteSearchBar } from "components/site-searchbar/SiteSearchBar";

interface NaviPageContentProps {
    showPageHeader: boolean;
}

export const NaviPageContent: React.FC<NaviPageContentProps> = ({
    showPageHeader
}) => {
    const { t } = useTranslation("page-titles");

    const {
        title,
        state,
        data: { badgeConfig, upgradeConfig, backNavigation, backNavigationText }
    }: Route<Data> = useCurrentRoute();

    const header = useSelector((s: RootState) => s.header);

    const badgeConfigClaim = useClaimCheck(badgeConfig?.claim);
    const showBadge = useMemo(() => {
        if (badgeConfig == null) return false;
        if (badgeConfig.claim == null) return true;

        const shouldHaveClaim = badgeConfig.claimCheck ?? true;
        return (
            (shouldHaveClaim && badgeConfigClaim) ||
            (!shouldHaveClaim && !badgeConfigClaim)
        );
    }, [badgeConfig, badgeConfigClaim]);

    const upgradeConfigClaim = useClaimCheck(upgradeConfig?.claim);
    const showUpgrade = useMemo(() => {
        if (upgradeConfig == null) return false;
        if (upgradeConfig.claim == null) return true;

        const shouldHaveClaim = upgradeConfig.claimCheck ?? true;
        return (
            (shouldHaveClaim && upgradeConfigClaim) ||
            (!shouldHaveClaim && !upgradeConfigClaim)
        );
    }, [upgradeConfig, upgradeConfigClaim]);

    return (
        <>
            <div className="row">
                <main className="container-fluid">
                    <NotFoundBoundary render={() => <h1>Not Found</h1>}>
                        <div>
                            {showPageHeader && (
                                <PageContentHeader
                                    backNavigation={
                                        header?.backNavigation ?? backNavigation
                                    }
                                    backNavigationText={
                                        header?.backNavigationText ??
                                        t(backNavigationText)
                                    }
                                    title={header?.title ?? t(`${title}`)}
                                    subTitle={
                                        header?.subTitle ??
                                        (i18n.exists(
                                            `page-titles:subheaders:${title}`
                                        )
                                            ? t(
                                                  `page-titles:subheaders:${title}`
                                              )
                                            : null)
                                    }
                                    subTitleLink={state?.subheaderLink}
                                    subTitleLinkText={t(
                                        `page-titles:link:${title}`
                                    )}
                                    showBadge={showBadge}
                                    badgeClass={badgeConfig?.class}
                                    badgeLabel={t(
                                        `page-titles:badges:${badgeConfig?.label}`
                                    )}
                                    showUpgrade={showUpgrade}
                                    upgradeLabel={t(
                                        `buttons:${upgradeConfig?.label}`
                                    )}
                                    customButtons={header?.customButtons}
                                    autoSave={header?.autoSave}
                                    autoSaveText={header?.autoSaveText}
                                    inlineCustomButton={
                                        header?.inlineCustomButton
                                    }
                                    shouldHeaderWrap={header?.shouldHeaderWrap}
                                    showBreadcrumbs={header?.showBreadcrumbs}
                                    breadcrumbLabels={header?.breadcrumbLabels}
                                />
                            )}

                            <NotificationContainer fixed />
                            <View />
                            <MinimizedModalTabManager />
                        </div>
                    </NotFoundBoundary>
                </main>
            </div>
        </>
    );
};
