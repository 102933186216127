import React from "react";
import { mount, route, compose, redirect, map, withView } from "navi";
import { View } from "react-navi";

// Components
import { BaseLayout } from "views/BaseLayout";
import { ProfilePage } from "./views/page-content/profile/ProfilePage";
import { NaviDashboardLayout as DashboardLayout } from "./views/NaviDashboardLayout";
import { ForgotPasswordPage } from "views/login/ForgotPasswordPage";
import { HomePage } from "./views/page-content/home/HomePage";
import { LoginPage } from "./views/login/LoginPage";
import { NewPaymentMethodPage } from "./views/page-content/subscription/payment-method/NewPaymentMethodPage";
import { PaymentSuccessPage } from "./views/page-content/subscription/payment-success/PaymentSuccessPage";
import { PrimaryPaymentMethodPage } from "./views/page-content/subscription/payment-method/set-primary/PrimaryPaymentMethodPage";
import { RemovePaymentMethodPage } from "./views/page-content/subscription/payment-method/remove/RemovePaymentMethodPage";
import { RenewSubscriptionPage } from "./views/page-content/subscription/renew-subscription/RenewSubscriptionPage";
import { ResetPasswordPage } from "views/login/ResetPasswordPage";
import { SubscriptionChangeSuccessPage } from "./views/page-content/subscription/subscription-change-success/SubscriptionChangeSuccessPage";
import { TutorialsPage } from "./views/page-content/tutorials/TutorialsPage";
import { PlatformsPage } from "views/page-content/platforms/PlatformsPage";
import { PlatformPage } from "views/page-content/platforms/platform/PlatformPage";
import { ConnectPlatformPage } from "views/page-content/platforms/connect-platform/ConnectPlatformPage";
import { PlatformFormPage } from "views/page-content/platforms/platform/forms/PlatformFormPage";
import { PlatformId } from "views/page-content/platforms/types";
import { CloudPage } from "views/page-content/cloud/CloudPage";
import { UploadAssetPage } from "views/page-content/cloud/upload/UploadAssetPage";
import { AssetDetailsPage } from "views/page-content/cloud/asset-details/AssetDetailsPage";
import { ExperimentalFeaturesPage } from "./views/page-content/experimental-features/ExperimentalFeaturesPage";
import { DownloadsPage } from "./views/page-content/downloads/DownloadsPage";
import { DownloadsStandalone } from "./views/page-content/downloads/DownloadsStandalone";
import { RemoteGuestsPage } from "views/page-content/remote-guests/RemoteGuestsPage";
import { WorkspacesPage } from "views/page-content/workspaces/WorkspacesPage";
import { AuthorizePage } from "views/authorize/AuthorizePage";
import { ManageInventoriesPage } from "views/page-content/manage-inventories/ManageInventoriesPage";
import { MyTeamPage } from "views/page-content/my-team/MyTeamPage";
import { ConfirmationWrapper } from "views/page-content/subscription/confirm-subscription/ConfirmationWrapper";
import { StripeElement } from "./components/stripe/StripeElement";
import { RawFileDetailsPage } from "views/page-content/cloud/raw-file-details/RawFileDetailsPage";
import { ConfirmEmailPage } from "./views/login/ConfirmEmailPage";
import { LinkAccountPage } from "views/page-content/link-account/LinkAccountPage";
import { IframePlatformPage } from "views/page-content/platforms/platform/IFramePlatformPage";
import { AddExternalChannelSettingsPage } from "views/page-content/platforms/platform/external-channel-settings/AddExternalChannelSettingsPage";
import { EditCustomRTMPChannelPage } from "views/page-content/platforms/platform/custom-rtmp/EditCustomRTMPChannelPage";
import { AddCustomRTMPChannelPage } from "views/page-content/platforms/platform/custom-rtmp/AddCustomRTMPChannelPage";
import { SpeedTestPage } from "views/page-content/speed-test/SpeedTestPage";
import { SimulcastForm } from "views/page-content/platforms/platform/simulcast/SimulcastForm";
import { SimulcastDestinations } from "views/page-content/platforms/platform/simulcast/simulcast-destinations/SimulcastDestinations";
import { SimulcastPlatformDestinationSelector } from "views/page-content/platforms/platform/simulcast/simulcast-destinations/simulcast-destination-selector/SimulcastDestinationSelector";
import { SimulcastExistingBroadcasts } from "views/page-content/platforms/platform/simulcast/simulcast-existing-broadcasts/SimulcastExistingBroadcasts";
import { MyLivestreamsPage } from "views/page-content/my-livestreams/MyLivestreamsPage";
import { withTrackedView } from "./helpers/analyticsHelpers";
import { decode } from "helpers/utils";
import { ManageInventoryItemsPage } from "views/page-content/manage-inventories/ManageInventoryItemsPage";
import { BrandProfilePage } from "views/page-content/brand-profile/BrandProfile";
import { AccountDeletionPage } from "views/page-content/account-deletion/AccountDeletionPage";
import { NewSelectPlanPage } from "views/page-content/subscription/select-plan/NewSelectPlanPage";
import { NewSubscriptionPage } from "views/page-content/subscription/NewSubscriptionPage";
import { NewSubscriptionPageV2 } from "views/page-content/SubscriptionPage";
import { GatedContentPage } from "views/page-content/gated-content";
import { VideoLibraryPage } from "views/page-content/video-library";
import { AnalyticsPage } from "views/page-content/analytics/AnalyticsPage";
import { CatalogPage } from "views/page-content/CatalogPage";
import { CollectionPage } from "views/page-content/CatalogPage/CollectionPage";
import { FeaturedTrailerPage } from "views/page-content/CatalogPage/FeaturedTrailerPage";
import { CollectionCustomizationPage } from "views/page-content/CatalogPage/CollectionPage/CollectionCustomizationPage";
import { CatalogCustomizationPage } from "views/page-content/CatalogPage/CatalogCustomizationPage";
import { CollectionGatedContentPage } from "views/page-content/CatalogPage/CollectionPage/CollectionGatedContentPage";
import { CollectionDefaultDisplayPage } from "views/page-content/CatalogPage/CollectionPage/CollectionDefaultDisplayPage";
import { CollectionLinksContentPage } from "views/page-content/CatalogPage/CollectionPage/CollectionLinksContentPage";
import { CatalogSubscriptionPage } from "views/page-content/CatalogPage/CatalogSubscriptionPage";
import { DevicePreviewPage } from "views/page-content/DevicePreviewPage";
import { OnboardingWrapper } from "views/page-content/onboarding/OnboardingWrapper";

export interface Data {
    badgeConfig?: {
        claimCheck: boolean;
        claim: string;
        label: string;
        class: string;
    };
    upgradeConfig?: {
        claimCheck: boolean;
        claim: string;
        label: string;
    };
    /** Specifies a location for the page to go back to when the back button is pressed. If no page is given, the arrow will not appear. */
    backNavigation?: string;
    /** This will appear next to the back button if it is included. This should always be directed towards the parent page. It is put into a t function so it should be used as translation key. */
    backNavigationText?: string;
}

const useNewBillingPage = import.meta.env.VITE_USE_NEW_BILLING_PAGE === "true";

export const videoCMSRoutes = {
    "/video-library": mount({
        "/": route({
            title: "video-library",
            view: <VideoLibraryPage />
        })
    }),
    "/catalog": mount({
        "/": route({
            title: "catalog",
            view: <CatalogPage />
        }),
        "/featured-trailer": route({
            title: "featured-trailer",
            view: <FeaturedTrailerPage />
        }),
        "/customization": route({
            title: "catalog-customization",
            view: <CatalogCustomizationPage />
        }),
        "/catalog-subscription": route({
            title: "catalog-subscription",
            view: <CatalogSubscriptionPage />
        }),
        "/collections": mount({
            "/": redirect("/catalog"),
            "/:videoPlayerId": mount({
                "/": map((req) => {
                    return route<object, Data>({
                        view: <CollectionPage {...req.params} />,
                        data: {
                            badgeConfig: {
                                claimCheck: false,
                                claim: "videoplayer",
                                label: "preview",
                                class: "badge-preview"
                            },
                            upgradeConfig: {
                                claimCheck: false,
                                claim: "videoplayer",
                                label: "upgrade-business"
                            }
                        }
                    });
                }),
                "/gated-content": map((req) => {
                    return route<object, Data>({
                        title: "gated-content",
                        view: <CollectionGatedContentPage {...req.params} />
                    });
                }),
                "/default-display": map((req) => {
                    return route<object, Data>({
                        title: "default-display",
                        view: <CollectionDefaultDisplayPage {...req.params} />
                    });
                }),
                "/links": map((req) => {
                    return route<object, Data>({
                        title: "links",
                        view: <CollectionLinksContentPage {...req.params} />
                    });
                }),
                "/customization": map((req) => {
                    return route<object, Data>({
                        title: "customization",
                        view: <CollectionCustomizationPage {...req.params} />
                    });
                })
            })
        })
    }),
    "/gated-content": mount({
        "/": route({
            title: "gated-content",
            view: <GatedContentPage />
        })
    }),
    "/analytics": mount({
        "/": route({
            title: "analytics",
            view: <AnalyticsPage />
        })
    })
};
export const creationToolsRoutes = {
    "/downloads": mount({
        "/": route({
            title: "downloads",
            view: <DownloadsPage />
        })
    }),
    "/platforms": mount({
        "/": route({
            title: "platforms",
            view: <PlatformsPage />
        }),
        "/simulcast": mount({
            "/": route({
                title: "simulcast",
                view: <SimulcastExistingBroadcasts />
            }),
            "/create": route({
                title: "simulcast",
                view: <SimulcastForm />
            }),
            "/destinations": mount({
                "/": route({
                    title: "simulcast",
                    view: <SimulcastDestinations />
                }),
                "/:platformId/add": map((req) => {
                    return route({
                        title: "Destinations",
                        view: (
                            <SimulcastPlatformDestinationSelector
                                {...req.params}
                            />
                        )
                    });
                }),
                "/custom/connect": route({
                    title: "custom-rtmp",
                    view: (
                        <AddCustomRTMPChannelPage finalUrl="/platforms/simulcast/destinations/custom/add" />
                    )
                }),
                "/:platformId/connect": map((req) => {
                    return route({
                        title: "connect-account",
                        view: <ConnectPlatformPage {...req.params} />
                    });
                })
            })
        }),
        "/switcherplayer": mount({
            "/": redirect("/platforms/stream/switcherplayer")
        }),
        "/connect/:platformId": map((req) => {
            const { platformId } = req.params;

            if (platformId === PlatformId.BoxCast) {
                return redirect(`/platforms/${platformId}`);
            }

            return route({
                title: "connect-account",
                view: <ConnectPlatformPage {...req.params} />
            });
        }),
        "/custom-rtmp": mount({
            "/": route({
                title: "custom-rtmp",
                view: <AddCustomRTMPChannelPage />
            }),
            "/:id": route((req) => {
                return {
                    title: "custom-rtmp",
                    view: <EditCustomRTMPChannelPage {...req.params} />
                };
            })
        }),
        "/:platformId": mount({
            "/": route((req) => {
                const { platformId } = req.params;

                if (platformId === PlatformId.BoxCast) {
                    return {
                        title: "platforms",
                        view: <IframePlatformPage {...req.params} />
                    };
                }

                return {
                    title: "platforms",
                    view: <PlatformPage {...req.params} {...req.query} />
                };
            }),
            "/channels/add": route((req) => {
                return {
                    title: "platforms",
                    view: (
                        <AddExternalChannelSettingsPage
                            {...req.params}
                            {...req.query}
                        />
                    )
                };
            })
        }),
        "/stream/:platformId": route((req) => {
            return {
                title: "platforms",
                view: <PlatformFormPage {...req.params} {...req.query} />
            };
        }),
        "/destination/:platformId": route((req) => {
            return {
                title: "platforms",
                view: <PlatformPage {...req.params} {...req.query} />
            };
        })
    }),
    "/switcher-cloud": mount({
        "/": route({
            title: "switcher-cloud",
            view: <CloudPage />
        }),
        "/asset/:assetId": route((req) => {
            return {
                title: "switcher-cloud",
                view: <AssetDetailsPage {...req.params} />
            };
        }),
        "/raw-file/:assetId": route((req) => {
            return {
                title: "switcher-cloud",
                view: <RawFileDetailsPage {...req.params} />
            };
        }),
        "/upload": route({
            title: "switcher-cloud",
            view: <UploadAssetPage />
        })
    }),
    "/remote-guests": route({
        title: "remote-guests",
        view: <RemoteGuestsPage />
    }),
    "/my-livestreams": route({
        title: "my-livestreams",
        view: <MyLivestreamsPage />
    }),
    "/experimental-features": mount({
        "/": route({
            title: "experimental-features",
            view: <ExperimentalFeaturesPage />
        }),
        "/:featureId": route((req) => {
            return {
                title: "experimental-features",
                view: <ExperimentalFeaturesPage {...req.params} />
            };
        })
    })
};
export const accountSettingsRoutes = {
    "/subscription": mount({
        "/": route({
            title: "subscription",
            view: (
                <StripeElement>
                    {useNewBillingPage ? (
                        <NewSubscriptionPageV2 />
                    ) : (
                        <NewSubscriptionPage />
                    )}
                </StripeElement>
            )
        }),
        "/subscribe": mount({
            "/": route((req) => {
                return {
                    title: "subscription",
                    view: <NewSelectPlanPage {...req.params} {...req.query} />
                };
            }),
            "/:priceId": route((req) => {
                return {
                    title: "subscription",
                    view: <ConfirmationWrapper {...req.params} />
                };
            })
        }),
        "/renew": route((req) => {
            return {
                title: "renew-subscription",
                view: <RenewSubscriptionPage {...req.params} />
            };
        }),
        "/success": route({
            title: "payment-successful",
            view: <PaymentSuccessPage />
        }),
        "/change-success": route((req) => {
            return {
                title: "subscription-success",
                view: <SubscriptionChangeSuccessPage {...req.params} />
            };
        }),
        "/payment-method": mount({
            "/": route({
                title: "add-payment",
                view: (
                    <StripeElement>
                        <NewPaymentMethodPage />
                    </StripeElement>
                )
            }),
            "/:id/primary": route((req) => {
                return {
                    title: "set-primary-payment",
                    view: <PrimaryPaymentMethodPage {...req.params} />
                };
            }),
            "/:id/remove": route((req) => {
                return {
                    title: "remove-payment",
                    view: <RemovePaymentMethodPage {...req.params} />
                };
            })
        })
    }),
    "/profile": mount({
        "/": route({
            title: "change-password",
            view: <ProfilePage />
        })
    }),
    "/brand-profile": mount({
        "/": route({
            title: "brand-profile",
            view: <BrandProfilePage />
        })
    })
};
export const helpRoutes = {
    "/tutorials": route({
        title: "video-tutorials",
        view: <TutorialsPage />
    })
};
export const sidebarRoutes = {
    "/welcome": mount({
        "/": redirect("/home")
    }),
    "/home": route({
        title: "home",
        view: <HomePage />
    }),
    "/videos": mount({
        "/": redirect("/video-library")
    }),

    // redirect /providers to /platforms
    "/providers": mount({
        "/": redirect("/platforms"),
        "/:platformId": map((req) => {
            const { platformId } = req.params;

            return redirect(`/platforms/${platformId}`);
        }),
        "/:platformId/channels/add": map((req) => {
            const { platformId } = req.params;

            return redirect(
                `/platforms/${platformId}/channels/add${req.search}`
            );
        })
    }),
    "/cloud": mount({
        "/": redirect("/switcher-cloud"),
        "/upload": redirect("/switcher-cloud/upload")
    }),

    "/vc": mount({
        "/": route(() => {
            document.location.replace("https://videochat.switcherstudio.com");
            return {};
        }),
        "/:roomId": route((req) => {
            const { roomId } = req.params;
            document.location.replace(
                `https://videochat.switcherstudio.com/${roomId}`
            );
            return {};
        })
    }),
    "/videochat": mount({
        "/": redirect("/remote-guests")
    }),
    "/video-chat": mount({
        "/": redirect("/remote-guests")
    }),

    "/billing": redirect("/subscription"),

    "/settings": mount({
        "/": redirect("/platforms/custom-rtmp")
    }),
    "/reseller": mount({
        "/": redirect("/manage-inventories"),
        "/inventories": redirect("/manage-inventories"),
        "/inventories/:resellerInventoryId": map((req) => {
            const { resellerInventoryId } = req.params;

            return redirect(`/manage-inventories/${resellerInventoryId}`);
        })
    }),
    "/manage-inventories": mount({
        "/": route({
            title: "manage-inventories",
            view: <ManageInventoriesPage />
        }),
        "/:resellerInventoryId": route((req) => {
            return {
                title: "manage-inventory-items",
                view: <ManageInventoryItemsPage {...req.params} />
            };
        })
    }),

    /** Info: https://w3c.github.io/webappsec-change-password-url/ */
    "/.well-known/change-password": redirect("/profile"),

    "/teamselection": redirect("/workspaces"),

    "/experimentalfeatures": mount({
        "/": redirect("/experimental-features"),
        "/:featureId": map((req) => {
            const { featureId } = req.params;

            return redirect(`/experimental-features/${featureId}`);
        })
    }),
    "/help": mount({
        "/": route({
            view: () => {
                window.open("https://support.switcherstudio.com", "_blank");
                return null;
            }
        })
    }),
    "/workspaces": route({
        title: "workspaces",
        view: <WorkspacesPage />
    }),
    "/my-team": mount({
        "/": route({
            title: "my-team",
            view: <MyTeamPage />
        })
    })
};

const routes = compose(
    mount({
        "/welcome": mount({
            "/": redirect("/home")
        }),
        "/login": withTrackedView(
            <BaseLayout>
                <View />
            </BaseLayout>,
            route((req) => {
                const { redirect, code } = req.query;

                return {
                    title: "login",
                    view: (
                        <LoginPage
                            redirect={decode(redirect)}
                            code={decode(code)}
                        />
                    )
                };
            })
        ),
        "/forgotpassword": redirect("/forgot-password"),
        "/forgot-password": withTrackedView(
            <BaseLayout>
                <View />
            </BaseLayout>,
            route({
                title: "forgot-password",
                view: <ForgotPasswordPage />
            })
        ),
        "/invitation": withTrackedView(
            <BaseLayout>
                <View />
            </BaseLayout>,
            route((req) => {
                return {
                    title: "reset-password",
                    view: (
                        <ResetPasswordPage
                            {...req.params}
                            isInvitation={true}
                        />
                    )
                };
            })
        ),
        "/resetpassword": redirect((req) => {
            return `/reset-password${req.search}`;
        }),
        "/reset-password": withTrackedView(
            <BaseLayout>
                <View />
            </BaseLayout>,
            route((req) => {
                return {
                    title: "reset-password",
                    view: <ResetPasswordPage {...req.params} />
                };
            })
        ),
        "/register": redirect((req) => {
            return `/getting-started${req.search}`;
        }),
        "/register-external": redirect((req) => {
            return `/getting-started${req.search}`;
        }),
        "/create-account": redirect((req) => {
            return `/getting-started${req.search}`;
        }),
        "/download-switchercast": withTrackedView(
            <View />,
            route({
                title: "download-switcherCast",
                view: <DownloadsStandalone />
            })
        ),
        "/getting-started": withTrackedView(
            <View />,
            route((req) => {
                return {
                    title: "getting-started",
                    view: <OnboardingWrapper {...req.params} />
                };
            })
        ),
        "/confirmemail": withTrackedView(
            <BaseLayout>
                <View />
            </BaseLayout>,
            route((req) => {
                return {
                    title: "confirm-email",
                    view: <ConfirmEmailPage {...req.params} />
                };
            })
        ),
        "/authorize": withView(
            <BaseLayout hideFlags={true}>
                <View />
            </BaseLayout>,
            route((req) => {
                return {
                    title: "authorize",
                    view: <AuthorizePage {...req.query} />
                };
            })
        ),
        "/account-deletion": withTrackedView(
            <DashboardLayout>
                <View />
            </DashboardLayout>,
            route(() => ({
                title: "Delete Account",
                view: <AccountDeletionPage />
            }))
        ),
        "/editquality/:platformId": redirect((req) => {
            const { platformId } = req.params;
            return `/platforms/${platformId}${req.search}`;
        }),
        "/device-preview": withTrackedView(
            <View />,
            route((req) => {
                return {
                    title: "device-preview",
                    view: <DevicePreviewPage {...req.params} />
                };
            })
        ),
        "*": withTrackedView(
            <DashboardLayout>
                <View />
            </DashboardLayout>,
            mount({
                "/": redirect("./home"),
                ...videoCMSRoutes,
                ...creationToolsRoutes,
                ...accountSettingsRoutes,
                ...helpRoutes,
                ...sidebarRoutes,
                "/getstarted": mount({
                    "/": redirect("/tutorials")
                }),

                "/linkaccount": route({
                    title: "connect-account",
                    view: <LinkAccountPage />
                }),
                "/speedtest": route((req) => {
                    return {
                        title: "speed-test",
                        view: <SpeedTestPage {...req.params} />
                    };
                })
            })
        )
    })
);

export default routes;
