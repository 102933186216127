import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { NewSubscriptionGridV2 } from "./NewSubscriptionGridV2";
import { InvoiceTableV2 } from "./InvoiceTableV2";
import {
    SilverSunnStripeSubscription,
    StripePrice,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { displayDate } from "helpers/time";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import {
    mustBeSubscriptionOwner,
    useRedirectIfDisallowed
} from "hooks/useRedirectIfDisallowed";
import { StripeStatus } from "../platforms/types";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { NextPaymentOnV2 } from "./NextPaymentOnV2";
import styles from "./index.module.scss";
import { useNavigate } from "hooks/useNavigate";
import { ManagePlanModal } from "components/modal/ManagePlanModal";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { NewEntitlementGridV2 } from "./NewEntitlementGridV2";
import { removeQueryParams } from "helpers/url";
import { useGetStripePrice } from "hooks/useGetStripePrice";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import { SetupIntentElement } from "components/stripe/SetupIntentElement";
import { useCheckoutRedirect } from "hooks/useCheckoutRedirect";
import {
    refreshToken,
    getUserInfo,
    getUserOrgs,
    getDefaultUserOrg
} from "store/user/thunks";

export interface SubscriptionWithPrice {
    subscription?: SilverSunnStripeSubscription;
    price?: StripePrice;
}

export const NewSubscriptionPageV2: React.FC = () => {
    // Extract couponCode and planId from the query parameters
    const { searchParams } = useMemo<URL>(
        () => new URL(window.location.href),
        []
    );
    const [couponCode, setCouponCode] = useState(
        searchParams.get("couponCode")
    );
    const planId = searchParams.get("planId")?.toUpperCase();
    const hideSubscriptionPlans =
        searchParams.get("hideSubscriptions") === "true";

    useRedirectIfDisallowed(mustBeSubscriptionOwner);

    const { t, i18n } = useTranslation();
    const { navigate } = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((state: RootState) => state.user);

    const [hasActiveSub, setHasActiveSub] = useState<boolean>(false);
    const [stripePrice, setStripePrice] = useState<StripePrice>();
    const [detailedSubscriptions, setDetailedSubscriptions] = useState<
        SubscriptionWithPrice[]
    >([]);
    const [oneTimePrices, setOneTimePrices] = useState<StripePrice[]>();
    const { claimedCoupon } = useGetLastClaimedCoupon({
        couponCode: couponCode
    });
    const [preselectedPrice, setPreselectedPrice] = useState<
        StripePrice | StripePriceAnonymous
    >();
    const anonymousPlan = useGetStripePrice(planId)?.plan;

    const {
        data: prices,
        loading: stripePricesLoading,
        dispatchApiRequest: getStripePrices
    } = useSwitcherClient((client) => client.userStripePrices_GetStripePrices, {
        args: [userInfo.UserId],
        requestImmediately: true
    });

    /** if user has been redirected here to add a NON subscription plan, filter out the subscription plans */
    useEffect(() => {
        if (hideSubscriptionPlans) {
            setOneTimePrices(prices?.filter((p) => !p?.IsRecurring));
        }
    }, [searchParams, prices, hideSubscriptionPlans]);

    /** if there is a planId in the querystring params, use it to preselect the plan
     * in the modal and advance the user to the checkout step */
    useMemo(() => {
        if (!!planId) {
            const planIsPublic = prices?.find((p) => p?.Id === planId);
            if (!!planIsPublic) {
                return setPreselectedPrice(planIsPublic);
            } else if (anonymousPlan) {
                return setPreselectedPrice(anonymousPlan);
            }
        }
        return undefined;
    }, [prices, anonymousPlan, planId]);

    /** if there is a plan/price associated with the coupon in the querystring,
     * preselect that plan in the modal and advance the user to the checkout step */
    useEffect(() => {
        const priceAssociatedWithCoupon =
            claimedCoupon?.ResellerInventoryItem?.ResellerInventory?.Price;
        if (couponCode && !!priceAssociatedWithCoupon) {
            setPreselectedPrice(priceAssociatedWithCoupon);
        }
    }, [claimedCoupon?.ResellerInventoryItem, couponCode]);

    const { data: customer, dispatchApiRequest: getCustomer } =
        useSwitcherClient((client) => client.stripe_GetCustomer, {
            requestImmediately: true,
            onError: (e) => {
                rollbar.error(
                    "Error getting user stripe customer subscription info",
                    e
                );
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:subscription-info-error"
                    })
                );
            },
            onSuccess: (data) => {
                const isLapsedSub =
                    userInfo?.Roles?.includes("Lapsed") &&
                    userInfo?.Status !== StripeStatus.Incomplete;
                const isTrialSub = userInfo?.Roles?.includes("Trial");
                const isShopifyCustomer =
                    userInfo?.ActiveProduct?.discriminator?.includes(
                        "ShopifyProduct"
                    );

                if (
                    !data ||
                    ((isLapsedSub || isTrialSub) &&
                        data.StripeCreditCards?.length === 0 &&
                        !isShopifyCustomer)
                ) {
                    navigate("/subscription");
                } else {
                    data.StripeCreditCards?.sort((a, b) =>
                        a.Default === b.Default ? 0 : a.Default ? -1 : 1
                    );
                    const activeSub = data?.StripeSubscriptions.find(
                        (s) =>
                            s.Status === StripeStatus.Active ||
                            s.Status === StripeStatus.Trialing
                    );
                    setHasActiveSub(!!activeSub);

                    if (!!activeSub) {
                        getStripePrices([userInfo.UserId, activeSub.PlanId]);
                    }
                }
            }
        });

    const { data: entitlements, dispatchApiRequest: getEntitlements } =
        useSwitcherClient(
            (client) => client.userEntitlements_getActiveUserEntitlements,
            { requestImmediately: true, args: [userInfo.UserId] }
        );

    const isAppleSub = useMemo(() => {
        return (
            userInfo.ActiveProduct?.discriminator === "AppleProduct" &&
            userInfo.IsRecurring === true
        );
    }, [userInfo]);

    // combined subscriptions, products and price
    useEffect(() => {
        if (!!customer && !!prices) {
            const detailedSubs: SubscriptionWithPrice[] =
                customer.StripeSubscriptions.map((sub) => {
                    const matchingPrice = prices.find(
                        (p) => p?.Id === sub?.PlanId
                    );
                    setStripePrice(matchingPrice);
                    return {
                        subscription: sub,
                        price: matchingPrice
                    };
                });
            setDetailedSubscriptions(detailedSubs);
        }
    }, [customer, prices]);

    const displayCost = useCallback(
        (amount?: number, showPeriodText?: boolean) => {
            let periodText = "";
            if (!!stripePrice && hasActiveSub) {
                periodText =
                    stripePrice.RecurringInterval === "year"
                        ? t("subscription:per-year")
                        : t("subscription:per-month");
            }
            return `${((amount || 0) / 100).toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            })}${showPeriodText ? ` ${periodText}` : ""}`;
        },
        [stripePrice, hasActiveSub, t]
    );

    const _displayDate = (date?: string) => {
        return displayDate(date, i18n.language, "dddd, MMMM D, YYYY");
    };

    const handleCheckoutSuccess = useCallback(async () => {
        await dispatch(refreshToken());

        await Promise.allSettled([
            dispatch(getUserInfo()),
            dispatch(getUserOrgs()),
            dispatch(getDefaultUserOrg()),
            getCustomer(),
            getEntitlements()
        ]);

        removeQueryParams();

        dispatch(
            addNotification({
                type: NotificationType.Success,
                message: t("subscription:success")
            })
        );
    }, [dispatch, getCustomer, getEntitlements, t]);

    useCheckoutRedirect({
        onSuccess: async () => {
            try {
                await handleCheckoutSuccess();
            } catch (e) {
                rollbar.error("Error refreshing user info", e);

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:user-info-load-error")
                    })
                );
            }
        },
        onFailure: () => {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("subscription-page:payment-error")
                })
            );
        }
    });

    const openManagePlanModal = useCallback(() => {
        dispatch(
            setActiveModal({
                id: "manage-plan",
                type: Modals.ManagePlanModal,
                component: (
                    <SetupIntentElement>
                        <ManagePlanModal
                            preselectedPrice={preselectedPrice}
                            prices={
                                !hideSubscriptionPlans ? prices : oneTimePrices
                            }
                            couponCode={couponCode}
                            stripePricesLoading={stripePricesLoading}
                            customer={customer}
                            onCheckoutSuccess={handleCheckoutSuccess}
                            onCheckoutError={() => {
                                dispatch(
                                    addNotification({
                                        type: NotificationType.Danger,
                                        message: t(
                                            "subscription-page:payment-error"
                                        )
                                    })
                                );
                            }}
                        />
                    </SetupIntentElement>
                )
            })
        );
    }, [
        couponCode,
        customer,
        dispatch,
        handleCheckoutSuccess,
        hideSubscriptionPlans,
        oneTimePrices,
        preselectedPrice,
        prices,
        stripePricesLoading,
        t
    ]);

    /** automatically open the modal if query params exist */
    useEffect(() => {
        const fragment = window.location.hash;
        const openModal = fragment?.includes("#openModal");
        if (
            openModal ||
            hideSubscriptionPlans ||
            (preselectedPrice && couponCode != undefined)
        ) {
            openManagePlanModal();
            /** prevent modal from reopening */
            setCouponCode(undefined);
        }
    }, [
        couponCode,
        searchParams,
        preselectedPrice,
        hideSubscriptionPlans,
        openManagePlanModal
    ]);

    return (
        <div className="row">
            <div className={`${styles["subscription-page-wrapper"]} col`}>
                {isAppleSub ? (
                    <div className={styles["current-plan-row"]}>
                        <p>
                            {t("subscription-page:apple-subscription-message")}
                        </p>
                    </div>
                ) : (
                    <>
                        <div className={styles["current-plan-row"]}>
                            {!hasActiveSub && entitlements?.length ? (
                                <NewEntitlementGridV2
                                    entitlements={entitlements}
                                    openManagePlanModal={openManagePlanModal}
                                />
                            ) : (
                                <NewSubscriptionGridV2
                                    subscriptions={detailedSubscriptions}
                                    displayCostFn={displayCost}
                                    openManagePlanModal={openManagePlanModal}
                                />
                            )}
                            <NextPaymentOnV2
                                customer={customer}
                                refetchCustomer={getCustomer}
                                activeEntitlement={
                                    !hasActiveSub && entitlements?.[0]
                                }
                                activePlan={
                                    hasActiveSub && detailedSubscriptions[0]
                                }
                            />
                        </div>
                        <InvoiceTableV2
                            displayCostFn={displayCost}
                            displayDateFn={_displayDate}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
