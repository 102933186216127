import { useCallback, useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import rollbar from "helpers/rollbar";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { FeaturedVideoType, SpecificVideo } from "./SpecificVideo";
import { RadioCard, RadioCardsContainer } from "components/radio-cards";
import { AppDispatch } from "store/store";
import { exists } from "helpers/booleans";
import { usePageHeader } from "hooks/usePageHeader";
import { EmbedPreview } from "components/embed-preview";
import { useSettableMemo } from "hooks/useSettableMemo";
import {
    VideoPlayerAspectRatio,
    VideoPlayerEmbeddedDisplay,
    VideoPlayerIdleState
} from "@switcherstudio/switcher-api-client";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useCatalogAccessBanner } from "../../hooks/useCatalogAccessBanner";

export const CollectionDefaultDisplayPage = ({
    videoPlayerId
}: {
    videoPlayerId?: string;
}) => {
    useCatalogAccessBanner();
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const { userInfo } = useSelector((s: RootState) => s.user);
    const dispatch = useDispatch<AppDispatch>();
    const [embedPreviewKey, setEmbedPreviewKey] = useState(0);

    const { data: selectedVideoPlayer } = useSwitcherClient(
        (client) => client.videoPlayers_GetVideoPlayer,
        {
            requestImmediately: true,
            args: [videoPlayerId]
        }
    );

    const [videoPlayer, setVideoPlayer] = useSettableMemo(
        () => selectedVideoPlayer?.VideoPlayer,
        [selectedVideoPlayer]
    );

    const [statusToggle, setStatusToggle] = useSettableMemo(
        () => videoPlayer?.IdleState,
        [videoPlayer]
    );

    const { loading: isSaving, dispatchApiRequest: putVideoPlayer } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_PutVideoPlayer,
            {
                requestImmediately: false,
                hideLoading: true,
                onSuccess: (data): void => {
                    setVideoPlayer(data);
                    setEmbedPreviewKey((prevKey) => prevKey + 1);
                }
            }
        );

    usePageHeader({
        title: t("collection-page:collections-settings-default-display"),
        showBreadcrumbs: true,
        autoSave: isSaving,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings"),
            t("collection-page:collections-settings-default-display")
        ]
    });

    const [selectedBroadcastId, setSelectedBroadcastId] =
        useSettableMemo<string>(
            () => videoPlayer?.IdleBroadcastId,
            [videoPlayer]
        );

    const handleSelectBroadcast = useCallback(
        async (broadcastId: string) => {
            setSelectedBroadcastId(broadcastId);

            try {
                await putVideoPlayer([
                    videoPlayer?.Id,
                    {
                        VideoPlayer: {
                            ...videoPlayer,
                            IdleBroadcastId: broadcastId
                        }
                    },
                    userInfo?.ProjectId
                ]);
            } catch (e) {
                rollbar.error(
                    "Error updating video player status to next event",
                    e
                );
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("player-status-page:errors:next-live")
                    })
                );
            }
        },
        [
            dispatch,
            putVideoPlayer,
            setSelectedBroadcastId,
            t,
            userInfo?.ProjectId,
            videoPlayer
        ]
    );

    const handleChange = useCallback(
        async (_statusToggle: VideoPlayerIdleState) => {
            if (statusToggle === _statusToggle) return;

            setStatusToggle(_statusToggle);

            try {
                await putVideoPlayer([
                    videoPlayer?.Id,
                    {
                        VideoPlayer: exists(videoPlayer)
                            ? {
                                  ...videoPlayer,
                                  IdleState: _statusToggle
                              }
                            : {
                                  IdleState: _statusToggle,
                                  AspectRatio: videoPlayer?.AspectRatio
                              }
                    },
                    userInfo?.ProjectId
                ]);
            } catch (e) {
                rollbar.error(
                    "Error updating video player status to next event",
                    e
                );
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("player-status-page:errors:next-live")
                    })
                );
            }
        },
        [
            dispatch,
            putVideoPlayer,
            setStatusToggle,
            statusToggle,
            t,
            userInfo?.ProjectId,
            videoPlayer
        ]
    );

    return (
        <div className="row">
            <div className="col-xl-4">
                <RadioCardsContainer>
                    <RadioCard<VideoPlayerIdleState>
                        id={VideoPlayerIdleState._2}
                        name="video-player-statuses"
                        content={
                            <>
                                <div
                                    className={
                                        styles["radio-card-content-container"]
                                    }
                                >
                                    <h6>{t("player-status-page:playlist")}</h6>
                                    <p>
                                        {t(
                                            "player-status-page:playlist-description"
                                        )}
                                    </p>
                                </div>
                            </>
                        }
                        onSelect={handleChange}
                        selectedCardId={statusToggle}
                    />
                    <RadioCard
                        id={VideoPlayerIdleState._3}
                        name="video-player-statuses"
                        content={
                            <>
                                <div
                                    className={
                                        styles["radio-card-content-container"]
                                    }
                                >
                                    <h6>
                                        {t("player-status-page:featured-video")}
                                    </h6>
                                    <p>
                                        {hasCatalogClaim
                                            ? t(
                                                  "player-status-page:featured-video-description2"
                                              )
                                            : t(
                                                  "player-status-page:featured-video-description"
                                              )}
                                    </p>
                                </div>
                            </>
                        }
                        onSelect={handleChange}
                        selectedCardId={statusToggle}
                        expandedContent={
                            <>
                                <div
                                    className={
                                        styles["select-video-button-container"]
                                    }
                                >
                                    <SpecificVideo
                                        selectedBroadcastId={
                                            selectedBroadcastId ||
                                            videoPlayer?.IdleBroadcastId
                                        }
                                        onSelectBroadcastId={
                                            handleSelectBroadcast
                                        }
                                        featuredVideoType={
                                            FeaturedVideoType.Catalog
                                        }
                                        videoPlayerId={videoPlayerId}
                                    />
                                </div>
                            </>
                        }
                    />
                    <RadioCard
                        id={VideoPlayerIdleState._1}
                        name="video-player-statuses"
                        content={
                            <>
                                <div
                                    className={
                                        styles["radio-card-content-container"]
                                    }
                                >
                                    <h6>
                                        {t("player-status-page:live-countdown")}
                                    </h6>
                                    <p>
                                        {t(
                                            "player-status-page:live-countdown-description"
                                        )}
                                    </p>
                                </div>
                            </>
                        }
                        onSelect={handleChange}
                        selectedCardId={statusToggle}
                    />
                </RadioCardsContainer>
            </div>

            <div className={`col-xl-8 ${styles["embed-container"]}`}>
                <EmbedPreview
                    embedString={selectedVideoPlayer?.EmbedCode || ""}
                    playerId={videoPlayerId}
                    key={embedPreviewKey}
                    isVertical={
                        selectedVideoPlayer?.VideoPlayer?.EmbeddedDisplay ===
                            VideoPlayerEmbeddedDisplay._0 &&
                        selectedVideoPlayer?.VideoPlayer?.AspectRatio ===
                            VideoPlayerAspectRatio.NineBySixteen
                    }
                    hasMaxHeight={
                        selectedVideoPlayer?.VideoPlayer?.EmbeddedDisplay !==
                        VideoPlayerEmbeddedDisplay._0
                    }
                />
            </div>
        </div>
    );
};
